import React from 'react';
import './dashboard.scss';
import Header from '../Header';
import '../../../node_modules/react-simple-tree-menu/dist/main.css';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import Product from './product';
import Total from './total';
import { Modal } from 'react-bootstrap';
import Selectlist from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {reactLocalStorage} from 'reactjs-localstorage';
import axios from 'axios';
import queryString from 'query-string';
import $ from "jquery";
import { css } from "@emotion/core";
import moment from 'moment';
var config = require('../../Api')

var productesid = ""
$(document).ready(function(){

  $('.dropdown-submenu > span').on("click", function(e) {

    var submenu = $(this);
    $('.dropdown-submenu .dropdown-menu').removeClass('show');
    submenu.next('.dropdown-menu').addClass('show');
    
    e.stopPropagation();
});

$('.dropdown').on("hidden.bs.dropdown", function() {
   
    $('.dropdown-menu.show').removeClass('show');
}); 

 });

 const override = css`
  display: block;
  margin: 3;
  border-color: #C1D72E;
`;

var discountamount= ""
var discounttype = ""
var dateexpires= ""
var stationsArrs = ""
var currentDate = moment().format("DD/MM/YYYY");
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
   this.options = countryList().getData()  
    this.state = {
      options: this.options,
      value: {value:'AE',label:'United Arab Emirates' },
      products :[],
      categories :[],
      customers : [],
      showing: false,
      getproduct: [],
      productcategories: [],
      filtermenu:[],
      productnewDate :[],
      productterms: [],
      productbyauthor : [],
      ticketpdf: [],
      discountedproduct: [],
      checkoutshow : false,
      ordersuccessed: false,
      ordersuccessful: false,
      total: 0,
     quantity : 0,
     clicks : 1,
     clickproductid : "",
     show: false,
     showcustomer: false,
     getuser : {
      first_name: "",
      last_name: "",
      email: "",
      billing :  {
      address_1: "",
      address_2: "",
      city: "",
      company: "",
      country: "",
      first_name: "",
      last_name: "",
      postcode: "",
      state: "",
    
      
     
      }
      },
      carttotal : "",
      totaldiscount : "",
      cartfees:"",
      cartfeestax:"",
     selectedValue : "Cash",
     selectednote : "",
     guestcheckoutshow: false,
     outstoskshow: false,
     loading: true,
     guestcheckouts: false,
     couponcode:"",
     selectedcopoun: "",
     outstosk :"",
     guestcode: "",
     guestcodeerr : "",
     couponcodeerr : "",
     completeorder: [],
     paymentmenthd:"",
     productsno: "",
     outstoserr : "",
     membershipname:false,
     filtervalues: false,
     outstockverify :false,
     prductid : ""
     
    }
  
    this.calculateTotal = this.calculateTotal.bind(this);
    this.Createuser = this.Createuser.bind(this)  
   
}

componentDidMount() {
  this.Getproductlist();
  this.Getcategories();
  this.Getpaymentmethod();
  reactLocalStorage.setObject('selectuser', "");   
  } 


  Getproductlist=() =>{
 //alert("hiiiiii")
 this.setState({
  loading: true,  
})
    var apidata = config.default.themeurl;
   axios.get(apidata+"product-list", {})
      .then((response) => {
        const getproduct = response.data
        const product =  getproduct.products
        const filter =  getproduct.filter
        this.setState({
          products : product,
          productcategories :  filter.product_categories,
          productnewDate :  filter.newDate,
          productterms : filter.product_terms,
          productbyauthor : filter.authors,
          loading: false,
          productsno: "",
          filtervalues: false
       })   

       $('.dssubmenu').hide();
  

  })  
  }

  Getpaymentmethod=() =>{

    var api = config.default.WooCommercelatest;
    api.getAsync('payment_gateways').then((response) => {
      const paymentmenthds = JSON.parse(response.toJSON().body)
       
         this.setState({
          paymentmenthd : paymentmenthds,
           
        })   
      })


  }
  filtertoggle=() =>{
    $('.dssubmenu').toggle();

  }

  filterproducts = (filtervalue,value, filtername )=> {
    var eventdates = ""
    var productcats  = ""
    var producttags = ""
    var eventauthor = ""
    
     if(value == "1"){
      eventdates = filtervalue

     }
     if(value == "2"){

       productcats = filtervalue
  
     }
     if(value == "3"){
 
       producttags= filtervalue
      
     }
     if(value == "4"){
       eventauthor= filtervalue
     }
   
    $('.dssubmenu').hide();
    $('.dropdown-menu.show').removeClass('show');
  
    this.setState({
      loading: true,
      filtervalues: filtername,
  
   })  

    var apidata = config.default.themeurl;
  
    axios.get(apidata+"product-list?event_dates="+eventdates+"&product_cats="+productcats+"&product_tags="+producttags+"&event_author="+eventauthor, {})
       .then((response) => {
         const getproduct = response.data
         const product =  getproduct.products
     
         this.setState({
           products : product,
           loading: false,
           productsno: ""
        })   
   }, (error) => {
    this.setState({
       loading: false,
       products : [],
       productsno : "There Are No Events"
    })   
  }) 

  }

Getcategories(){

  var api = config.default.WooCommerce;
  api.getAsync("products/categories").then((result) => {

   const getcategories =  JSON.parse(result.toJSON().body)
   this.setState({
    categories : getcategories   
  })   
   
    });


}

getsearchproduct = (event) => {
  if(event.key === 'Enter'){
  var api = config.default.WooCommercelatest;
  if(this.searchproduct.value !== "" && this.searchproduct.value !== undefined ){
    var searchproducts = this.searchproduct.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
    if(isNaN(searchproducts)){
      
        api.getAsync('products?search='+searchproducts+'&status=publish&per_page=99').then((response) => {
        const getproducts = JSON.parse(response.toJSON().body)
       
       if(getproducts.length === 0){
         //this.Getproductlist()
         this.setState({
           loading: false, 
           filtervalues: false ,
           productsno : "There Are No Events",
           products :[]
         }) 
     
       }else{
         this.setState({
           products : getproducts,
           loading: false, 
           filtervalues: false,
           productsno : ""    
         }) 
     
       } 
        
         });

    }else {
      api.getAsync('products?sku='+searchproducts+'&status=publish').then((response) => { 

   const getproducts = JSON.parse(response.toJSON().body)
  
  if(getproducts.length === 0){
   // this.Getproductlist()
    this.setState({
      loading: false, 
      filtervalues: false,
      productsno : " No such event found" ,
      products :[]
    }) 

  }else{
    this.setState({
      products : getproducts,
      loading: false, 
      productsno : "",
      filtervalues: false    
    }) 

  } 
   
    });

  }
  
  } else{
    this.Getproductlist()


  }
}
}

addtocart = (productid)=> {
  
  productesid =  [...productesid, productid]
  this.setState({ 
    loading: true,
  });
  
  this.getcartproduct(productesid, productid)
}
getcartproduct(productesid, productid){
  var api = config.default.WooCommerce;
api.getAsync('products/?include='+productesid).then((response) => {
  const getproducts = JSON.parse(response.toJSON().body)
  //alert(getcustomers)
  this.setState({
   getproduct : getproducts,
    showing: !this.state.showing,
   showing: true,
   clicks: this.state.clicks+1,
   clickproductid :productid,
   loading: false,
 })   
 //reactLocalStorage.setObject('addtocartproduct', getproducts);
  
   });

  }

 
  showModal = () => {
    this.setState({ show: true, checkoutshow: false });
  }

  hideModal = () => {
    this.setState({ show: false });
  }

  checkoutshowModal = () => {
    this.setState({ checkoutshow: true });
  }

  checkouthideModal = () => {
    this.setState({ checkoutshow: false });
  }

  addcustomerModal = () => {
    this.setState({ showcustomer: true, show: false,invaliduserErr:'',  getuser : { 
      first_name: "",
      last_name: "",
      email: "",
billing :  {
address_1: "",
address_2: "",
city: "",
company: "",
country: "",
first_name: "",
last_name: "",
postcode: "",
state: "",
}
} });

  }

  hidecustomerModal = () => {
    this.setState({ showcustomer: false , show: true});
    //this.Createuser()
  }

  calculateTotal(price, qty) {
    
    this.setState({
      total: this.state.total + price,
      quantity : qty < 0 ? this.state.quantity-1 : this.state.quantity+1
    });  
  }



  getsearchvalue = (event) => {
    if(event.key === 'Enter'){
    var api = config.default.WooCommerce;
    var searchcustomers = this.search.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
    if(isNaN(searchcustomers)){
  var api = config.default.WooCommerce;
  api.getAsync('customers?search='+this.search.value+'&role=all&per_page=99').then((response) => {

   const getcustomers = JSON.parse(response.toJSON().body)
   //alert(getcustomers)
   
   this.setState({
    customers : getcustomers   
  }) 
  if(getcustomers.length === 0){

    this.setState({
      customerserror : "There are no Customer"   
    }) 

  }else{
    this.setState({
      customerserror : ""   
    }) 

  }  
   
    });
  } else {

    var apidata = config.default.themeurl;

    axios.get(apidata+"user-contact/"+this.search.value, {})
            .then((response) => {
   
          
            const getcustomers = response.data
            if(getcustomers== false){
         
              this.setState({
                customerserror : "No such customer found" ,
                customers : []
              }) 
          
            }else{
              this.setState({
                customerserror : ""   ,
                customers : getcustomers 
              }) 
             } 
    
           
   
            })

          }
      
        }
  
  }
  Createuser(){
    const { getuser }  = this.state 
   
    if (getuser.email !== "" && getuser.first_name !== "" && getuser.last_name !== "" ) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if ( re.test(getuser.email) ) {
    const createuser = {
      email: getuser.email,
      first_name: getuser.first_name,
      last_name: getuser.last_name,
      
     
  
      billing: {
        first_name: getuser.billing.firstname1 === undefined ? getuser.first_name :  getuser.billing.firstname1,
        last_name: getuser.billing.lastname1 === undefined ? getuser.last_name :  getuser.billing.lastname1,
        company:(getuser.billing.company === "" || getuser.billing.company=== undefined)   ? "Unknown" : getuser.billing.company,
        address_1:  (getuser.billing.address_1 === "" || getuser.billing.address_1=== undefined)   ? "Unknown" : getuser.billing.address_1,
        address_2:  (getuser.billing.address_2 === "" || getuser.billing.address_2=== undefined)   ? "Unknown" : getuser.billing.address_2,
        city: (getuser.billing.city === "" || getuser.billing.city=== undefined)   ? "Unknown" : getuser.billing.city,
        state: (getuser.billing.state === "" || getuser.billing.state=== undefined)   ? "Unknown" : getuser.billing.state,
        postcode: (getuser.billing.postcode === "" || getuser.billing.postcode=== undefined)   ? "Unknown" : getuser.billing.postcode,
        country: (getuser.billing.country === "" || getuser.billing.country=== undefined)   ? "Unknown" : getuser.billing.country,
        email:(getuser.billing.email1 === "" || getuser.billing.email1=== undefined)   ? getuser.email :  getuser.billing.email1,
        phone: (getuser.billing.phone === "" || getuser.billing.phone=== undefined)   ? "Unknown" :  getuser.billing.phone 
      },
      shipping: {
        first_name: getuser.billing.first_name,
        last_name: getuser.billing.last_name,
        company: getuser.billing.company,
        address_1: getuser.billing.address_1,
        address_2: getuser.billing.address_2,
        city: getuser.billing.city,
        state: getuser.billing.state,
        postcode: getuser.billing.postcode,
        country: getuser.billing.country
      }
    };
    //console.log(createuser)
  
   if(this.state.guestcheckouts !== true ){
  
    if (this.state.getusersid !== undefined && this.state.getusersid !=="" ){
      var api = config.default.WooCommerce;
      
      api.postAsync("customers/"+ this.state.getusersid, createuser).then((response) => {

        const customers = JSON.parse(response.toJSON().body)
        if( customers.message !== "" &&  customers.message !== undefined){
    
         this.setState({
          invaliduserErr:  customers.message,
      });
    
        }else{
          reactLocalStorage.setObject('selectuser', customers);
          this.hidecustomerModal()
          this.hideModal();
          this.setState({ checkoutshow: false });
          this.setState({
            invaliduserErr:  customers.message,
            getusersid: "",
        });
        }
    
       
        })
        .catch((error) => {
         
          this.setState({
            invaliduserErr:error.response.message,
        });
        });
  
  
    } else {
     createuser.username= getuser.email
     createuser.password= "123456789!@#$%"
   
    var api = config.default.WooCommerce;
    api.postAsync("customers", createuser).then((response) => {
      const customers = JSON.parse(response.toJSON().body)
      
    if( customers.message !== "" &&  customers.message !== undefined){

     //alert("hiiiiiiiiiiiiiiiiii")
     this.setState({
      invaliduserErr:  customers.message,
  });

    }else{
      reactLocalStorage.setObject('selectuser', customers);
      this.hidecustomerModal();
      this.hideModal();
      this.setState({ checkoutshow: false });
      this.setState({
        invaliduserErr:  customers.message,
    });
    }



      })
      .catch((error) => {
        this.setState({
          invaliduserErr:error.response.message,
      });
      });
  
    }
  } else{
    createuser.id= "9999999999999999999999"
    reactLocalStorage.setObject('selectuser', createuser);
    this.hidecustomerModal();
      this.hideModal();

  }
  
    } else{
  
      this.setState({
        invaliduserErr:'Please Enter A Valid Email Address',
    });
  
  
    }
  
    } else{
  
      this.setState({
        invaliduserErr:'Please fill all required fields',
    });
  
  
    }
  
   }


   Userselect(customerid){
    var api = config.default.WooCommerce;
    api.getAsync('customers/'+customerid).then((response) => {
  
     const getusers = JSON.parse(response.toJSON().body)


     var apidata = config.default.themeurl;

 axios.get(apidata+"get-active-membership/"+customerid, {})
         .then((response) => {

         // console.log(response.data)
           this.setState({
            membershipname : response.data,
            invaliduserErr: ""
          })   

         })
     //alert(getcustomers)
     this.setState({
      getuser : getusers,
      showcustomer: true,
       show: false,
     checkoutshow: false,
      getusersid : getusers.id
    })   
    reactLocalStorage.setObject('selectuser', getusers);
     
      });
     
  }

  handleInputFieldChange = (event) => {
    const { target } = event;
    const name = target.name;
    if(name === "first_name"){
      this.setState(preState => ({
        ...preState,
        getuser: {
            ...preState.getuser,
           [name]: target.value,
            billing :  {
              ...preState.getuser.billing,
              // [name]: target.value,
              
              }
        },
    
   
    }));
    }
    else if(name === "last_name"){
      this.setState(preState => ({
        ...preState,
        getuser: {
            ...preState.getuser,
           [name]: target.value,
            billing :  {
              ...preState.getuser.billing,
              // [name]: target.value,
              
              }
        },
    
   
    }));
    }
    else if(name === "email"){
      this.setState(preState => ({
        ...preState,
        getuser: {
            ...preState.getuser,
           [name]: target.value,
            billing :  {
              ...preState.getuser.billing,
              // [name]: target.value,
              
              }
        },
    
   
    }));
    }
    else{
      this.setState(preState => ({
        ...preState,
        getuser: {
            ...preState.getuser,
            [name]: target.value,
            billing :  {
              ...preState.getuser.billing,
              [name]: target.value,
              
              }
        },
    
   
    }));
    }
  };
  
  changeHandler = value => {
    this.setState({ value })
  //alert(JSON.stringify(value.value))
  const values = JSON.stringify(value.value)
    this.setState(preState => ({
      ...preState,
      getuser: {
         // ...preState.getuser,
          //[name]: target.value,
          billing :  {
            ...preState.getuser.billing,
            ['country']: values.replace(/['"]+/g, ''),
            
            }
      },
  
  
  }));
  } 

  checkoutuser = () => {

    this.setState({ checkoutshow: true,
   
    });
  
    const checkoutuser = reactLocalStorage.getObject('selectuser');
   if(checkoutuser.id !=="" && checkoutuser.id !== undefined ){
    this.setState({  loading: true})
      var apidata = config.default.themeurl;
    
      //var stationsArr = []
      const stationsArr =  this.state.getproduct.map((product,index) => {
        //var cart= []
        if(reactLocalStorage.getObject('product'+product.id).qty !== 0){
        var cart = {
          product_id : product.id,
          price: product.regular_price,
          quantity :   reactLocalStorage.getObject('product'+product.id).qty
          }
          
       return cart;
        }
       })
      
       const checkoutuserdata = {
        user_id : checkoutuser.id,
        cart_total : parseFloat(this.state.total).toFixed(2),
        cart : JSON.stringify(stationsArr)

       }
         axios.get(apidata+"get-user-membership-discount?"+queryString.stringify(checkoutuserdata), {})
         .then((response) => {
           this.setState({
            carttotal : response.data != null ? response.data.cart_total : "",
            totaldiscount : response.data != null ?  response.data.total_discount : "",
            discountedproduct : response.data != null ?  response.data.item : "",
            cartfees : response.data != null ?  response.data.cart_fees : "",
            cartfeestax : response.data != null ?  response.data.total_tax : "",
            loading: false
            //product_price : response.data.
          })   

         })


    }

  }
  ordernote = () => {
   //alert(this.otdersearch.value)
    this.setState({ selectednote: this.otdersearch.value });

  }

  checkoutcomplete = () => {
    var api = config.default.WooCommercelatest;
    const checkoutuserdsta = reactLocalStorage.getObject('selectuser');
    
    if(checkoutuserdsta !=="" && checkoutuserdsta !== undefined){
      this.setState({
        loading: true,
        checkoutshow: false,
      })  
    const checkoutdata =  { 
      "payment_method": this.state.selectedValue,
      "payment_method_title": this.state.selectedValue,
      "set_paid": true,
     // "customer_id": checkoutuserdsta.id,
        "customer_note": this.state.selectednote,
      "billing": {
        first_name:checkoutuserdsta.billing.first_name,
        last_name: checkoutuserdsta.billing.last_name,
        company: checkoutuserdsta.billing.company,
        address_1: checkoutuserdsta.billing.address_1,
        address_2: checkoutuserdsta.billing.address_2,
        city: checkoutuserdsta.billing.city,
        state: checkoutuserdsta.billing.state,
        postcode: checkoutuserdsta.billing.postcode,
        country: checkoutuserdsta.billing.country,
        email: checkoutuserdsta.billing.email,
        phone: checkoutuserdsta.billing.phone
      },
     
    }

    if(this.state.selectedcopoun !== undefined  && this.state.selectedcopoun !== "" ){

      const selcoupoun = this.state.selectedcopoun.map((selectedcpn,index) => {
         discountamount = selectedcpn.amount
         discounttype = selectedcpn.discount_type
        var selcoupouns = {
          //id : selectedcpn.id,
          code :   selectedcpn.code,
          amount :   selectedcpn.amount,
          discount_type :   selectedcpn.discount_type,
          individual_use: selectedcpn.individual_use,
           exclude_sale_items:"",
           minimum_amount: selectedcpn.minimum_amount
          }
          
       return selcoupouns;
       })
     
     checkoutdata.coupon_lines = selcoupoun
     
    }
 

       stationsArrs =  this.state.getproduct.map((product,index) => {
        var cart = {
          product_id : product.id,
          quantity :   reactLocalStorage.getObject('product'+product.id).qty,
         
          }
          return cart;
        })
    


  if(checkoutuserdsta.id !== undefined  && checkoutuserdsta.id !== "9999999999999999999999" ){
    checkoutdata.customer_id = checkoutuserdsta.id
    }

     if(this.state.totaldiscount !== "" && this.state.totaldiscount!== undefined && this.state.totaldiscount !== 0 ){
      const discounteditem =  this.state.discountedproduct.map((product,index) => {
  
        var cart = {
          product_id : product.product_id,
          price :  `${product.product_price}`,
          quantity :   reactLocalStorage.getObject('product'+product.product_id).qty,
          total_tax : `${product.product_tax}`,
          subtotal_tax: "0",//`${product.product_tax}`,
          subtotal : `${parseFloat(product.product_price * reactLocalStorage.getObject('product'+product.product_id).qty )}`,
          total : `${parseFloat(product.product_price * reactLocalStorage.getObject('product'+product.product_id).qty)}`,
        } 
       /*subtotal : `${parseFloat(product.product_price)-parseFloat(product.product_tax - 29.9)}`,*/
       return cart;
       })  
      checkoutdata.line_items = discounteditem

   if(this.state.cartfees !== "" && this.state.cartfees !== undefined){
     
      var cartfees = [{
        name	:	"Membership Limit Exceeded",
        total:  `${this.state.cartfees}`,
       //tax_status: "none",
      }] 

      checkoutdata.fee_lines = cartfees

    }
     } else{
      checkoutdata.line_items = stationsArrs

     }
     var authoruser = reactLocalStorage.getObject('user');
     var metadatas = [{
      key	:	"orderuserid",
      value:   `${authoruser}`,
    
    }] 
     checkoutdata.meta_data = metadatas

    api.postAsync('orders', checkoutdata).then((response) => {
      const completeorder= JSON.parse(response.toJSON().body)
      if(completeorder.id !=="" && completeorder.id !== undefined){
     
    
     reactLocalStorage.setObject('selectuser', "");
     this.setState({
      completeorder : completeorder,
      ordersuccessful: true,
        loading: false,
        getproduct : [],
        discountedproduct: [],
        selectedcopoun:[],
        totaldiscount:"",
        total: 0,
         quantity :0,
         guestcode: "",
         membershipname: "",
         customers : [],
      couponcode: "",
      outstockverify :false,
     
    }) 
     
    var apidata = config.default.themeurl;
     
    axios.get(apidata+"ticket/"+completeorder.id, {})
    .then((response) => {
    
      this.setState({
     
       loading: false,
     })   
    // productesid = ""
    })
    this.Getproductlist();
    productesid = ""
    discountamount = ""
    discounttype = ""
    this.searchproduct.value = ""
    this.search.value = ""

      }else{

        api.postAsync('orders', checkoutdata).then((response) => {
         
         const completeorder= JSON.parse(response.toJSON().body)
         reactLocalStorage.setObject('selectuser', "");
         this.setState({
          completeorder : completeorder,
          ordersuccessful: true,
            loading: false,
            getproduct : [],
            discountedproduct: [],
            selectedcopoun:[],
            totaldiscount:"",
            total: 0,
             quantity :0,
             guestcode: "",
             membershipname: "",
             customers : [],
          couponcode: "",
          outstockverify :false,
         
        }) 
         
        var apidata = config.default.themeurl;
         
        axios.get(apidata+"ticket/"+completeorder.id, {})
        .then((response) => {
        
          this.setState({
         
           loading: false,
         })   
        // productesid = ""
        })
        this.Getproductlist();
        productesid = ""
        discountamount = ""
        discounttype = ""
        this.searchproduct.value = ""
        this.search.value = ""
      });

      }
    

      });
    } else
    {


      this.setState({
        //invaliduserErr:"Please Create a new user or select a user",
        guestcheckoutshow: true, 

    });
  }
  }
  cancelordershowModal = () => {
    this.setState({ cancelordershow: true });
  }

  cancelorderhideModal = () => {
    this.setState({ cancelordershow: false });
  }

  guestcheckouthide = () => {
    this.setState({ guestcheckoutshow: false });
  }

 

  ordersuccessedhideModal = () => {
    this.setState({ ordersuccessed: false });
  }
  ordersuccessfulhideModal = () => {
    this.setState({ ordersuccessful: false });
  }
  ordersuccessegetticket = () => {
  var apidata = config.default.themeurl;
  this.setState({
    ordersuccessful: false,
   loading: true,
  }) 
    axios.get(apidata+"ticket/"+this.state.completeorder.id, {})
    .then((response) => {
      reactLocalStorage.setObject('selectuser', "");
      this.setState({
       ticketpdf : response.data,
       
       
       getproduct : [],
       showing: !this.state.showing,
      showing: false,
      total: 0,
      quantity :0,
      cancelordershow: false,
       
       ordersuccessed: true,
      
       loading: false,
     })   
     productesid = ""
    })
  }

  guestcheckout = () => {
   // alert(this.state.guestcode)
if(this.state.guestcode !=="" && this.state.guestcode !== undefined){
  var apidata = config.default.themeurl;
  var guestcheck  = {
    password : this.state.guestcode

  }

  axios.post(apidata+"guest_checkout", guestcheck)
  .then((response) => {
    const getresponce = response.data
    //console.log(getresponce)
    if(getresponce !== ""  && getresponce === true ){
    this.setState({ 
      guestcheckoutshow: false,
      checkoutshow: false,
     // showcustomer: true,
      guestcheckouts: true,
     });

     const createuser = {
      email: "guest@elfdubai.org",
      first_name: "Guest",
      last_name: "Account",
      
     
  
      billing: {
        first_name: "Guest",
        last_name: "Account",
        company: "emirates",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        postcode: "",
        country: "UAE",
        email: "guest@elfdubai.org",
        phone: "",
      },
     
    };

    createuser.id= "9999999999999999999999"
    reactLocalStorage.setObject('selectuser', createuser);
    this.checkoutcomplete()

    } else{
      this.setState({ 
      guestcodeerr: "Please Enter a Valid Guest Code"
    });

    }

})  
  
    }else{
      this.setState({ 
      guestcodeerr: "Please Enter a  Guest Code"
    });

    }

  }
cancelorder= () => {
 //alert("hiiii")
    reactLocalStorage.setObject('selectuser', "");
    this.setState({
      getproduct : [],
       showing: !this.state.showing,
      showing: false,
      total: 0,
      quantity :0,
      cancelordershow: false,
      carttotal :  "",
       totaldiscount :  "",
      discountedproduct :  [],
      cartfees : "",
    }) 
    productesid = ""
  }

  selectpaymentmethod = (event) => {
    this.setState({ selectedValue: event.target.value });
    //alert(this.state.selectedValue)
  };
  couponcodechange= (event) => {
    this.setState({couponcode: event.target.value});
    

  }

  guestcodechange= (event) => {
    this.setState({guestcode: event.target.value});
    

  }

  couponcodecheck= (event) => {
    this.setState({ 
      loading: true
  });
    var api = config.default.WooCommerce;

    if(this.state.couponcode !=="" && this.state.couponcode !== undefined){

    api.getAsync("coupons?code="+this.state.couponcode, ).then((response) => {
      const selectedcopouncode = JSON.parse(response.toJSON().body)
      this.setState({selectedcopoun: selectedcopouncode});
      this.state.selectedcopoun.map((selectedcpn,index) => {
        
        dateexpires = selectedcpn.expiry_date
        
        const expDate = moment(dateexpires);
        const expDateFormated = expDate .format('DD/MM/YYYY');
	  // alert(expDateFormated)
	   //alert(currentDate)
       if( expDateFormated <= currentDate){
        discountamount = selectedcpn.amount
        discounttype = selectedcpn.discount_type
     } else {
       
      discountamount = ""
      discounttype = ""
      dateexpires= ""
      this.setState({ 
        selectedcopoun : "",
        couponcodeerr: "Your Coupon Code is Expired",
        loading: false
    });
   

     }
       
       
      })
     if(selectedcopouncode.length <= 0){
      discountamount = ""
      discounttype = ""
      dateexpires= ""
      this.setState({ 
        couponcodeerr: "You Entered A Wrong Coupon Code",
        loading: false
    });


     }else if(dateexpires!== ""){
      this.setState({ 
        couponcodeerr: "",
        loading: false
    });
 
     }
     // alert(this.state.selectedcopoun[0].id)

    })
  } else {
    this.setState({ 
      couponcodeerr: "Please Enter a coupon Code",
      loading: false
  });

  }

  }

  couponcoderemove= () => {
   
   
      this.setState({selectedcopoun: "",
      couponcodeerr: "Coupon Removed Successfully",
      couponcode: ""
  
    });
     
        discountamount = ""
        discounttype = ""
        dateexpires= ""
       

  }

  outstoskhide = () => {
    this.setState({ outstoskshow: false ,  outstoserr : "" });
  }

  outstoskshow = (pid) => {
   // alert(pid)
    this.setState({ outstoskshow: true,
        prductid : pid });
  }

  outstoskchange= (event) => {
    this.setState({outstosk: event.target.value});
    

  }

  outstoskadd = () => {
    this.setState({ 
      loading: true
  });
 if(this.state.outstosk !=="" && this.state.outstosk !== undefined){
   var apidata = config.default.themeurl;
   var guestcheck  = {
     password : this.state.outstosk
 
   }
 
   axios.post(apidata+"soldout_session", guestcheck)
   .then((response) => {
     const getresponce = response.data
     //console.log(getresponce)
     if(getresponce !== ""  && getresponce === true ){
     this.setState({ 
      outstoskshow: false,
      outstockverify : true,
      loading: false,
      outstosk : "",
      outstoserr : ""
      });
 
 
     } else{
       this.setState({ 
        outstoserr: "Please Enter a Valid Out Of stock Code",
        loading: false
     });
 
     }
 
 })  
   
     }else{
       this.setState({ 
        outstoserr: "Please Enter a  Out Of stock Code",
        loading: false
     });
 
     }
 
   }




render() {
 const {getproduct,getuser }  = this.state
 const { showing } = this.state;
 const checkoutuser = reactLocalStorage.getObject('selectuser');
 var authoruser = reactLocalStorage.getObject('user');
// console.log("Hiiiii" + authoruser)
var downloadreceipt = config.default.downloadreceipt; 
 let checkouttotal = parseFloat(this.state.total).toFixed(2);
      let checkoutsubtotal = parseFloat(this.state.total / 1.05).toFixed(2);
      let  checkouttax = parseFloat(+checkouttotal - +checkoutsubtotal).toFixed(2);
      let checkoutquty = this.state.quantity;
      $(document).ready(function(){
        // $('.dsmainmenu').on("click", function(e) {
        //   $('.dssubmenu').toggle();
        //   alert("hiiiiiii")
        // });
        
        $('.dropdown-submenu > span').on("click", function(e) {
         // alert("hhhhhhhhh");
          var submenu = $(this);
          $('.dropdown-submenu .dropdown-menu').removeClass('show');
          submenu.next('.dropdown-menu').addClass('show');
          
          e.stopPropagation();
      });
      
      $('.dropdown').on("hidden.bs.dropdown", function() {
          // hide any open menus when parent closes
          $('.dropdown-menu.show').removeClass('show');
      }); 
      
       });
var component = this;
var productes = getproduct.map(function(product) {
  return (
    <Product
      name={product.name}
      price={product.regular_price}
      id={product.id}
      key={product.id}
      sku={product.sku}
      images={product.images[0]['src']}
      stcockquantity ={product.stock_quantity}
      managestock ={product.manage_stock}
      outstockv ={component.state.outstockverify}
     handleTotal={component.calculateTotal}
   
    />
  );
});  
//console.log(this.state.products)  
return (

      <div className="el-main">
        {/* Menu */}
        <Header/>
      
      
        <div className="el-flexrow">
        <Modal className="el-cancelorder-wrap" show={this.state.outstoskshow} handleClose={this.state.outstoskhide}>
                          <div className="el-cancelorder">
                              <div className="el-model-title">Are You Sure ?</div>
                              <p>Do You Really Want order Out Of Stock Product </p>
                             <div className="el-right">
                             <div className="el-checkout-inputwith-button el-checkoutalert">
                                              <div className="el-forminput-noicon">
                                                <input type="password" placeholder="Enter Password..." value={this.state.outstosk}  onChange={this.outstoskchange} />
                                              </div>
                                              <div className='coupon'>
                                              {this.state.outstoserr}     
                                              </div> 
                                          </div>   
                                <div className="el-button-wrap el-flxdr">
                                 
                                  <div className="el-btn el-checkout-btn" onClick={this.outstoskadd} > <i className="material-icons">check</i> Submit</div>   
                                  <div className="el-btn el-cancel-btn" onClick={this.outstoskhide} > Cancel</div>
                                </div>
                              </div>
                             {/* Checkout Container END */}                              
                              
                          </div>
                          </Modal> 
       
                    <Modal className="el-ordersuccessed-wrap" show={this.state.ordersuccessed} handleClose={this.ordersuccessedhideModal}>
                          <div className="el-cancelorder el-downloadpopup el-downloadpodash">
                              <div className="el-model-title">Download Ticket Here</div>
                          <p>Click on below links to download Ticket PDF  ORDER ID : {this.state.completeorder.id}</p>
                             <div className="el-left el-downloadtick-wrap">
                                <div className="el-button-wrap el-flxdr">
                  
                                {this.state.ticketpdf.map((ticketpdfs, index) => (
                                <div className="el-btn el-checkout-btn" key={index}>
                                  <a href={ticketpdfs.url}    target="_blank">
                                    <i className="material-icons">local_printshop</i> Download Ticket PDF 
                                  </a>
                                </div>     ))}       
                                 
                                  
                                  {/* <div className="el-btn el-cancel-btn" onClick={this.cancelorder} > <i className="material-icons">check</i> Remove</div> */}
                                </div>
                              </div>
                              <div className="el-right el-download-closebtn">
                                <div className="el-btn el-checkout-btn" onClick={this.ordersuccessedhideModal} > Close</div>   
                              </div>
                             {/* Checkout Container END */}                              
                              
                          </div>
                          </Modal>  

                          <Modal className="el-ordersuccessed-wrap" show={this.state.ordersuccessful} handleClose={this.ordersuccessfulhideModal}>
                          <div className="el-cancelorder el-downloadpopup el-downloadpodash">
                              <div className="el-model-title">Order Successed</div>
                          <p>ORDER ID : {this.state.completeorder.id}</p>
                             <div className="el-left el-downloadtick-wrap">
                                <div className="el-button-wrap el-flxdr">
                                <a href={downloadreceipt+ '&document_type=invoice&order_ids='+this.state.completeorder.id+'&order_key='+this.state.completeorder.order_key}    target="_blank">
              <i className="material-icons">local_printshop</i> Receipt
              </a>
                                </div>
                              </div>
                              <div className="el-right el-download-closebtn">
                                <div className="el-btn el-checkout-btn" onClick={this.ordersuccessfulhideModal} > Close</div>   
                              </div>
                             {/* Checkout Container END */}                              
                              
                          </div>
                          </Modal>  


                          <Modal className="el-cancelorder-wrap" show={this.state.guestcheckoutshow} handleClose={this.guestcheckouthide}>
                          <div className="el-cancelorder">
                              <div className="el-model-title">Are You Sure ?</div>
                              <p>Do You Really Want order with Guest Checkout </p>
                             <div className="el-right">
                             <div className="el-checkout-inputwith-button el-checkoutalert">
                                              <div className="el-forminput-noicon">
                                                <input type="password" placeholder="Enter Guest code..." value={this.state.guestcode}  onChange={this.guestcodechange} />
                                              </div>
                                              <div className='coupon'>
                                              {this.state.guestcodeerr}     
                                              </div> 
                                          </div>   
                                <div className="el-button-wrap el-flxdr">
                                 
                                  <div className="el-btn el-checkout-btn" onClick={this.guestcheckout} > <i className="material-icons">check</i> Submit</div>   
                                  <div className="el-btn el-cancel-btn" onClick={this.guestcheckouthide} > Cancel</div>
                                </div>
                              </div>
                             {/* Checkout Container END */}                              
                              
                          </div>
                          </Modal> 


                         <Modal className="el-cancelorder-wrap" show={this.state.cancelordershow} handleClose={this.cancelorderhideModal}>
                         { this.state.quantity >=1 ? 
                          <div className="el-cancelorder">
                              <div className="el-model-title">Are You Sure ?</div>
                              <p>Do You Really Want to Remove Cart Items </p>
                             <div className="el-right">
                                <div className="el-button-wrap el-flxdr">
                                 
                                  <div className="el-btn el-checkout-btn" onClick={this.cancelorderhideModal} > Cancel</div>   
                                  <div className="el-btn el-cancel-btn" onClick={this.cancelorder} > <i className="material-icons">check</i> Remove</div>
                                </div>
                              </div>
                                     
                          </div> : 
                          <div className="el-cancelorder">
                           <div className="el-model-title">Empty Cart</div>
                              <p>There are no product on Cart </p>
                             <div className="el-right">
                                <div className="el-button-wrap el-flxdr">
                                 
                                  <div className="el-btn el-checkout-btn" onClick={this.cancelorderhideModal} > Cancel</div>   
                                 
                                </div>
                              </div> </div>
                               }
                          </Modal>  


        {/* Left-Section */}
          <div className="el-left">
            
            {/* Left-Top-Bar */}
            <div className="el-btn-newfol" onClick={this.cancelordershowModal} >
              <i className="material-icons">add</i> New Order
            </div>
            {/* Left-Top-Bar */}
            
            {/* Product-List */}
            <div className="el-product-list">
              
              {/* Dropdown */}
              <div className="el-dropdown">
                {/* <i className="material-icons el-local-offer">local_offer</i> <span>Category</span> */}
              
                <div className="el-cat-button">
     
<nav className="navbar navbar-expand-sm navbar-light bg-light">
    <div className="collapse navbar-collapse" id="navbar1">
        <ul className="navbar-nav">
            <li className="nav-item dropdown">
                <span id="menu" data-toggle="dropdown" className="nav-link dropdown-toggle dsmainmenu" onClick={() => this.filtertoggle()} >Filter by {this.state.filtervalues !== false ? " :- "+ this.state.filtervalues : null}</span>
                <ul className="dropdown-menu  dssubmenu">
                    <li className="dropdown-item dropdown-submenu">
                        <span data-toggle="dropdown" className="dropdown-toggle">By Date</span>
                        <ul className="dropdown-menu">
                        {this.state.productnewDate.map((productnew, index) => (
                             <li className="dropdown-item dsdrop-select" key={index}    onClick={() => this.filterproducts(productnew.date, "1",productnew.date )}>
                               <span>{productnew.date}</span>
                         </li>
                           ))}  
                        </ul>
                    </li>
                    <li className="dropdown-item dropdown-submenu">
                        <span data-toggle="dropdown " className="dropdown-toggle">By Category</span>
                        <ul className="dropdown-menu">
                   
                           {this.state.productcategories.map((bycat, index) => (
                             <li className="dropdown-item dsdrop-select" key={index} onClick={() => this.filterproducts(bycat.term_id, "2", bycat.name)}>
                               <span>{bycat.name}</span>
                         </li>
                           ))}  
                           
                        </ul>

                    </li>

                    {/* <li className="dropdown-item dropdown-submenu">
                        <span data-toggle="dropdown" className="dropdown-toggle">By Strand</span>
                        <ul className="dropdown-menu">
                        {this.state.productterms.map((productterm, index) => (
                             <li className="dropdown-item dsdrop-select" key={index}  onClick={() => this.filterproducts(productterm.term_id, "3")}>
                               <span>{productterm.name}</span>
                         </li>
                           ))}  
                        </ul>

                    </li> */}
                    <li className="dropdown-item dropdown-submenu">
                        <span data-toggle="dropdown" className="dropdown-toggle">By Author</span>
                        <ul className="dropdown-menu">
                        {this.state.productbyauthor.map((productbyauthr, index) => (
                             <li className="dropdown-item dsdrop-select" key={index}  onClick={() => this.filterproducts(productbyauthr.ID, "4", productbyauthr.post_title)}>
                               <span>{productbyauthr.post_title}</span>
                         </li>
                           ))}  
                        </ul>

                    </li>
                    <li  onClick={()=>this.Getproductlist()} >
                        <span className="el-clearall" >Clear All</span>
                        </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>


                </div>                
              </div>
              
              {/* Dropdown */}
              
              {/* Search */}
              <div className="el-search">
                <i className="material-icons">search</i>
                <input type='text' placeholder="Search Events..." ref={input => this.searchproduct = input}  onKeyPress={this.getsearchproduct}  />
              </div>
              {/* Search */}
              
              <div className="el-list-inn">
                {/* Product-Item-Repeat */}
                {this.state.loading == true ?
        <div id="cover-spin" ></div> : null}  
            {this.state.productsno}
                {this.state.products.map((product, index) => (
               
         <div className="el-list-item" data-id ={product.id} key={index}    onClick={ product.stock_quantity > 0 || product.manage_stock == false || (this.state.outstockverify == true && this.state.prductid == product.id )? () => this.addtocart(product.id) : null} >
          {/*<div className="el-listitem-img-container">
         <img src={!!product.images[0]['src'] ? product.images[0]['src']  : product.images } alt={product.name} /> 
         </div>*/}
         <div className="el-product-info">
           <h3>{product.name}</h3>
           <span>Event No: {product.sku}  | DATE: {product.event_date ? product.event_date :
            (product.meta_data !=undefined)?
       
            product.meta_data.slice(0).reverse().map((eventdate, index) =>{
           if(eventdate.key==="WooCommerceEventsSelectDate"){
              
           return eventdate.value ? eventdate.value: "No Date Available" 
          //  }
            }})
           
            :"" }
            { (product.meta_data !=undefined)? product.meta_data.map((eventdate, index) =>{
              if(eventdate.key==="WooCommerceEventsHour"){
                 
               return eventdate.value ? " "+eventdate.value : "00"
               }
               if(eventdate.key==="WooCommerceEventsMinutes"){
                 
                 return  eventdate.value ? ":"+ eventdate.value : ":00"
                 }
                 if(eventdate.key==="WooCommerceEventsPeriod"){
                 
                   return eventdate.value?  ":"+ eventdate.value : ":00"
                   }
             }) : " " }
            
            
             | STOCK : {product.stock_quantity}</span>
            <span>VENUE : {product.event_location ? product.event_location :
            
            (product.meta_data !=undefined) ?
       
            product.meta_data.map((eventdate, index) =>{
           if(eventdate.key==="WooCommerceEventsLocation"){
              
           return eventdate.value ? eventdate.value: "No Date Available" 
           }
          }) : ""
            
            } </span>
           <p>AED {!!product.regular_price ? product.regular_price  : product.price } VAT </p>     
         </div>
         <div className="el-add-item">
          {/* <div>s:{reactLocalStorage.getObject('product'+product.id).qty} </div>
         <div>q:{this.state.quantity}</div> */}
         { product.stock_quantity > 0 || product.manage_stock == false || (this.state.outstockverify == true && this.state.prductid == product.id ) ?  
        <i className="material-icons">add</i>  : 
        <i className="material-icons el-green"   onClick={() => this.outstoskshow(product.id)}  >not_interested</i>
        }
        
        
          
         </div>
       </div>
            ))}
         
                {/* Product-Item-Repeat */}                
              </div>
            </div>
            {/* Product-List */}
        </div>
        
        {/* Right-Section */}
        <div className="el-right">
          {/* Cart */}
          { showing ?
          <div>
             
          <div className="el-cart">
        
              <div className="el-cart-content">
                <div className="el-open-order">
                  <div className="el-or-left">
                      <div className="el-or-title">Incomplete Order</div>
                      <div className="el-customer-btn">
                        <i className="material-icons">person</i>
                        <div className="el-customerbtn-info">
                          <div className="el-customer-name" onClick={this.showModal} >{!!(checkoutuser.id) ? ( '#'+ checkoutuser.id  +" "+ checkoutuser.email  +" "+  checkoutuser.first_name +" "+ checkoutuser.last_name) : " Guest Customer" }</div>
       
                          <Modal show={this.state.show} handleClose={this.hideModal}>
                          <div className="el-creatnewcust">
                              <div className="el-model-title">Create New Customer</div>
                              <div className="el-add-item" onClick={this.addcustomerModal}><span>Create New Customers</span>
                                <i className="material-icons">add</i>
                              </div>
                              <div className="el-creatcontent-wrap">
                              <div className="el-search">
                                <div className="el-searchinn">
                                  <i className="material-icons">search</i>
                                  <input type='text' placeholder="Search customers..." ref={input => this.search = input}  onKeyPress={this.getsearchvalue} />
                                </div>
                              </div>


                              <div className="el-left el-dsp-block le-size100">
                              <div className="el-creatcontent-wrap el-product-list">
                                <div className="el-ccwrap el-list-inn">
                                  {this.state.customerserror}
                                  {/* <p>The actions in this window were passed in as an array of React objects.</p> */}
                                  {this.state.customers.map((customer, index) => (
                                      <div className="el-list-item"  key={index}    onClick={() => this.Userselect(customer.id)}>
                                        <div className="el-listitem-img-container el-id-container">
                                          #{customer.id}
                                        </div>
                                        <div className="el-product-info">
                                          <h3>{!!(customer.first_name) ? (customer.first_name +" "+ customer.last_name) : customer.username} </h3>
                                          <p className="el-normal">{customer.email}</p>                    
                                        </div>
                                        <div className="el-add-item">
                                          <i className="material-icons">keyboard_arrow_right</i>
                                        </div>
                                      </div>
         
                                    ))}
                                </div>
                                </div>
                                </div>



                                <div className="el-right">
                                <div className="el-button-wrap">
                                  <div className="el-btn el-cancel-btn" onClick={this.hideModal} ><i className="material-icons">close</i> Cancel</div>
                                </div>
                                </div>
                              </div>
                          </div>
                          </Modal>
                        </div>
                      </div>
                  </div>
                  <div className="el-or-right">
                    <div className="el-order-status">In progress</div>
                    <div className="el-order-pmethod">
                      <i className="material-icons">payment</i> Cash
                    </div>
                  </div>
                </div>
                <div className="el-order-contain">
                {productes} 
                </div>
              </div>
              {/* OpenOrder */}
              <Total total={this.state.total}  quty={this.state.quantity} />
          </div>
          {/* Cart */}
          
          <Modal show={this.state.showcustomer} handleClose={this.hidecustomerModal}>
                          <div className="el-creatnewcust">
                            
                              <div className="el-cust-form">
                                  <h2>Customer Details</h2>
                                  <h2 className="el-order-status" >{this.state.membershipname !==false ? this.state.membershipname : null}</h2>
                {/* FirstName */}
                <div className="el-custform-row el-req">
                    <div className="el-custform-label">First Name</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text"    name='first_name'  value={getuser.first_name}     onChange={(event) => this.handleInputFieldChange(event, "first_name")}   required />
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div>
                {/* LastName */}
                <div className="el-custform-row el-req">
                    <div className="el-custform-label">Last Name</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text"  name='last_name'  value={getuser.last_name}  onChange={(event) => this.handleInputFieldChange(event, "last_name")}  required />
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div>
                {/* EmailAddress */}
                <div className="el-custform-row el-req">
                    <div className="el-custform-label">Email Address</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input  type="email"   name='email' name='email'  value={getuser.email}  onChange={(event) => this.handleInputFieldChange(event, "email")} required/>
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div>
                <h2>Billing Address</h2>
                {/* FirstName */}
                {/* <div className="el-custform-row el-req">
                    <div className="el-custform-label">First Name</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text"  name='first_name1' defaultValue={getuser.billing.first_name}   onChange={(event) => this.handleInputFieldChange(event, "first_name1")}  />
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div> */}
                {/* LastName */}                
                {/* <div className="el-custform-row el-req">
                    <div className="el-custform-label">Last Name</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text"   name='last_name1'  defaultValue={getuser.billing.last_name}   onChange={(event) => this.handleInputFieldChange(event, "last_name1")}  />
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div> */}
                {/* Company */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Company</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='company' />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* Address Line 1 */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Address Line 1</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='address_1'  defaultValue={getuser.billing.address_1}   onChange={(event) => this.handleInputFieldChange(event, "address_1")}  />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* Address Line 2 */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Address Line 2</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='address_2' defaultValue={getuser.billing.address_2}   onChange={(event) => this.handleInputFieldChange(event, "address_2")} />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* City */}
                <div className="el-custform-row">
                    <div className="el-custform-label">City</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='city'  defaultValue={getuser.billing.city}    onChange={(event) => this.handleInputFieldChange(event, "city")} />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* State */}
                <div className="el-custform-row">
                    <div className="el-custform-label">State</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text"  name='state' defaultValue={getuser.billing.state}   onChange={(event) => this.handleInputFieldChange(event, "state")}  />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* Postcode */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Postcode</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
           
                        <input type="text" name='postcode'   defaultValue={getuser.billing.postcode}   onChange={(event) => this.handleInputFieldChange(event, "postcode")}  />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}  
                </div>
                {/* Country */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Country</div>
            
                    <div className="el-custform-input">
                      <div className="el-forminput el-select">
                       {/*  <input type="text" name='country'  defaultValue={getuser.billing.country}  onChange={(event) => this.handleInputFieldChange(event, "country")} />*/}
                       <Select
          defaultValue={{value:'AE',label:'United Arab Emirates' }}  
        options={this.state.options}
        value={this.state.value}
        onChange={this.changeHandler}
      />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* EmailAddress */}
                {/* <div className="el-custform-row el-req">
                    <div className="el-custform-label">Email Address</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="email" name='email1' defaultValue={getuser.billing.email}   onChange={(event) => this.handleInputFieldChange(event, "email1")} />
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div> */}
                {/* Phone */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Phone</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='phone'  defaultValue={getuser.billing.phone}   onChange={(event) => this.handleInputFieldChange(event, "phone")} />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
              </div>
              <p className="text-danger">{this.state.invaliduserErr}</p>
              <div className="el-right el-flexgrowunset">
                <div className="el-button-wrap el-flxdr">
                  <div className="el-btn el-cancel-btn" onClick={this.hidecustomerModal} ><i className="material-icons">arrow_back</i> Back</div>
                  <div className="el-btn el-checkout-btn" onClick={this.Createuser} ><i className="material-icons">check</i> Submit</div>   
                </div>
              </div>
            </div>
          </Modal>
         
          <div className="el-button-wrap">
            <div className="el-empty"></div>
            <div className="el-btn el-cancel-btn"   onClick={this.cancelordershowModal} ><i className="material-icons">not_interested</i> Cancel</div>
            { this.state.quantity >=1 ?
   <div className="el-btn el-checkout-btn" onClick={this.checkoutuser} ><i className="material-icons">check</i> Checkout</div> : 
   <div className="el-btn el-checkout-btn"  ><i className="material-icons">check</i> Checkout</div> 

            }
            

              <Modal show={this.state.checkoutshow} handleClose={this.checkouthideModal}>
                          <div className="el-creatnewcust">
                              <div className="el-model-title">Checkout</div>

                              {/* Item Row START */}
                              <div className="el-itemwrap">
                              { this.state.totaldiscount ? 
                              <div>
                              {this.state.discountedproduct.map((product, index) => (
                             <div key={index}>
                            {reactLocalStorage.getObject('product'+product.product_id).qty !== 0 ?   
                                <div className="el-cart-content" key={index}>
                                  <div className="el-order-contain">
                                    <div>
                                      <div className="el-prdlist-item" id="" data-id="">
                                        <div className="el-cartline-qty">{reactLocalStorage.getObject('product'+product.product_id).qty }</div>
                                         {/*<div className="el-listitem-img-container">
                                          <img src={!!product.images[0]['src'] ? product.images[0]['src']  : product.images } alt="img1" /> 
                                        </div>*/}
                                        <div className="el-product-info">
                                          <h3>{product.product_title}</h3>
                                          <span>Event No : {product.sku}</span>
                                        </div>
                                        <div className="el-cartline-total">

                                          <span>AED { product.product_price}  | TAX : {product.product_tax} </span> 
                                        </div>
                                        <div className="el-cartline-total">
                                       
          <span><strong>AED { ((parseFloat(product.product_price)+parseFloat(product.product_tax))*reactLocalStorage.getObject('product'+product.product_id).qty).toFixed(2)}</strong></span> 
                                        </div>                                    
                                      </div>
                                    </div>
                                  </div>
                              </div> : null}     </div> )

                                      )}  </div>
                              
                              : <div>
                              {getproduct.map((product, index) => (
                             <div key={index}>
                            {reactLocalStorage.getObject('product'+product.id).qty !== 0 ?   
                                <div className="el-cart-content" key={index}>
                                  <div className="el-order-contain">
                                    <div>
                                      <div className="el-prdlist-item" id="" data-id="">
                                        <div className="el-cartline-qty">{reactLocalStorage.getObject('product'+product.id).qty }</div>
                                         {/*<div className="el-listitem-img-container">
                                          <img src={!!product.images[0]['src'] ? product.images[0]['src']  : product.images } alt="img1" /> 
                                        </div>*/}
                                        <div className="el-product-info">
                                          <h3>{product.name}</h3>
                                          <span>Event No : {product.sku}</span>
                                        </div>
                                        <div className="el-cartline-total">
              
                                          <span>AED { product.regular_price}</span>
                                        </div>
                                        <div className="el-cartline-total">
                                     
                                          <span><strong>AED { (product.regular_price*reactLocalStorage.getObject('product'+product.id).qty).toFixed(2)}</strong></span>
                                        </div>                                    
                                      </div>
                                    </div>
                                  </div>
                              </div> : null}     </div> )

                                      )}  </div> }

                              </div>
                              {/* Item Row END */}

                              {/* Item Total START */}
                              <div className="el-right">
                              <div className="el-cart">
                                <div className="el-totalof-cart">
                                  <div className="el-sub-total">
                                    
                                    { this.state.totaldiscount ? 
                                    <div>  
                                      <div className="el-st-row">
                                      <div className="el-st-firstcol">Subtotal</div>
                                      <span>AED {(this.state.carttotal- this.state.cartfees-this.state.cartfeestax).toFixed(2)}</span>
                                    </div>
                                    {this.state.cartfees ? <div className="el-st-row">
                                      <div className="el-st-firstcol">
                                        <strong>Cart Fees</strong>
                                      </div>
                                      <span><strong>AED {this.state.cartfees}</strong></span>
                                    </div> : null}
            
                                    {this.state.cartfeestax ?
                                    <div className="el-st-row">
                                      <div className="el-st-tax">VAT (5%)</div>
                                      <span>AED {this.state.cartfeestax}</span>
                                    </div> : null}
                                    <div className="el-st-row">
                                      <div className="el-st-firstcol">
                                        <strong>Total</strong>
                                      </div>
                                      <span><strong>AED {this.state.carttotal}</strong></span>
                                    </div> 
                                    {this.state.selectedcopoun === undefined ?  "" : this.state.selectedcopoun.length > 0 ?
                                    <div className="el-st-row">
                                      <div className="el-st-firstcol">
                                    <strong>Total After Coupon Applied    :  {discountamount} %</strong>
                                      </div>
                                      <span><strong>AED {(this.state.carttotal - (this.state.carttotal * discountamount/100).toFixed(2))}</strong></span>
                                    </div>
                                     : null }

                                     </div> : <div><div className="el-st-row">
                                      <div className="el-st-firstcol">Subtotal</div>
                                      <span>AED {checkoutsubtotal}</span>
                                    </div>
                                    <div className="el-st-row">
                                      <div className="el-st-tax">VAT (5%)</div>
                                      <span>AED {checkouttax}</span>
                                    </div>
                                    <div className="el-st-row">
                                      <div className="el-st-firstcol">
                                        <strong>Total</strong>
                                      </div>
                                      <span><strong>AED {checkouttotal}</strong></span>
                                    </div>
                                    {this.state.selectedcopoun === undefined ?  "" : this.state.selectedcopoun.length > 0 ?
                                    <div className="el-st-row">
                                      <div className="el-st-firstcol">
                                        <strong>Total After Coupon Applied    :  {discountamount} %</strong>
                                      </div>
                                      <span><strong>AED {checkouttotal - (checkouttotal * discountamount/100)}</strong></span>
                                    </div>
                                     : null }
                                     </div>
                                    
                                    }


                                    </div>                                    
                                </div>
                              </div>
                              </div>
                              {/* Item Total END */}

                             {/* Checkout Container START */}
                             <div className="el-checkout-contwrap">
                               <div className="el-checkoutbox">
                                  <div className="el-chekcoutopt-row">
                                    <div className="el-checkoutopt-title">
                                      Customer
                                    </div>
                                    <div className="el-checkoutopt-inn">
                                        <div className="el-checkout-guestcust">
                                          <i className="material-icons">person</i>
                                          <div className="el-checkout-cutinfo" onClick={this.showModal} >
                                            <div className="el-chout-custname">{!!(checkoutuser.id) ? ( '#'+ checkoutuser.id  +" "+ checkoutuser.email  +" "+  checkoutuser.first_name +" "+ checkoutuser.last_name) : " Guest Customer" }</div>
                                          </div>
                                         
                                        </div>
                                        {this.state.membershipname !==false ? this.state.membershipname : null}
                                    </div>
                                  </div>

                                  <div className="el-chekcoutopt-row">
                                      <div className="el-checkoutopt-title">
                                        Coupon Code
                                      </div>
                                      <div className="el-checkoutopt-inn">
                                          <div className="el-checkout-inputwith-button">
                                              <div className="el-forminput-noicon">
                                                <input type="text" placeholder="Enter coupon code..." value={this.state.couponcode}  onChange={this.couponcodechange} />
                                              </div>
                                              <div className="el-checkout-btnprimary"  onClick={this.couponcodecheck} >
                                              <i className="material-icons">check</i>
                                                Apply
                                              </div>
                                              {/* {this.state.selectedcopoun ? "Coupon Applied Successfully" : null} */}
                                              <div className='coupon'>
                                              {this.state.selectedcopoun === undefined ?  "" : this.state.selectedcopoun.length > 0 ? "Coupon Applied Successfully" :null}  

                                               {this.state.selectedcopoun === undefined ?  "" : this.state.selectedcopoun.length > 0 ? 
                                               <div className='couponremove' onClick={this.couponcoderemove}>
                                                 <i class="material-icons">highlight_off</i>
                                              Remove Coupon
                                               </div>
                                               :null}     
                                              {this.state.couponcodeerr}
                                              </div> 
                                          </div>                                      
                                      </div>
                                  </div>

                                  <div className="el-chekcoutopt-row">
                                      <div className="el-checkoutopt-title">
                                        Payment Method
                                      </div>
                                      <div className="el-checkoutopt-inn">
                                        {/* <InputLabel id="label">Age</InputLabel> */}
                                        <Selectlist labelId="label"  id="select" value={this.state.selectedValue}    onChange={this.selectpaymentmethod}>
                                          {/* <MenuItem value="cash" >cash</MenuItem> */}
                                          
                                          {this.state.paymentmenthd.map((paymentmenthod, index) => (
                                      <MenuItem value={paymentmenthod.title} key={index}>{paymentmenthod.title}</MenuItem>
                           ))}  
                                        </Selectlist>
                                      </div>
                                  </div>

                                  <div className="el-chekcoutopt-row">
                                      <div className="el-checkoutopt-title"></div>
                                      {/* <div className="el-checkoutopt-inn">
                                        <div className="el-checkout-recived">
                                          <div className="el-checkoutopt-title">Received</div>                                      
                                          <div className="el-checkoutopt-inn">
                                            <div className="el-forminput-num">
                                              <input type="text" placeholder="0.00" value="" />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="el-checkout-recived">
                                          <div className="el-checkoutopt-title"><strong>Change</strong></div>                                      
                                          <div className="el-checkoutopt-container">
                                            <span>AED0.00</span>
                                          </div>
                                        </div>
                                      </div> */}
                                  </div>

                                  <div className="el-chekcoutopt-row">
                                      <div className="el-checkoutopt-title">
                                        Order Note
                                      </div>
                                      <div className="el-checkoutopt-inn">
                                          <textarea className="el-checkoutopt-textarea" placeholder="Add an optional order note..." ref={input => this.otdersearch = input}  onChange={this.ordernote} ></textarea>
                                      </div>
                                  </div>

                                  {/* <div className="el-chekcoutopt-row">
                                      <div className="el-checkoutopt-title"></div>
                                      <div className="el-chekcoutopt-row cf-aligncenter">
                                          <div className="el-checkoutcust-note">
                                            Send note to customer
                                          </div>
                                          <div className="el-togglewrap">
                                            <i className="material-icons">toggle_off</i>
                                          </div>
                                      </div>
                                  </div> */}
                                </div>                               
                                <p className="text-danger"> {this.state.invaliduserErr}</p>
                             </div> 
                             <div className="el-right">
                                <div className="el-button-wrap el-flxdr">
                                  <div className="el-btn el-cancel-btn" onClick={this.checkouthideModal} ><i className="material-icons">arrow_back</i> Back</div>
                                  <div className="el-btn el-checkout-btn" onClick={this.checkoutcomplete} ><i className="material-icons">check</i> Complete</div>   
                                </div>
                              </div>
                             {/* Checkout Container END */}                              
                              
                          </div>
                          </Modal>         
          </div> 
          </div>
        : 
        <div className="el-empty-cart">
        <div>
        <i className="material-icons">shopping_cart</i>
          <h2>CART EMPTY</h2>
          <p> Add a product from the list</p>
        </div>
      </div>
        
        }



        </div>
        {/* Right-Section */}
      </div>
      </div>
    )}
}

export default Dashboard;