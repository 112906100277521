'use strict';
import WooCommerceAPI from 'woocommerce-api';
 
var WooCommerce = new WooCommerceAPI({
  url: 'https://www.emirateslitfest.com/',
  consumerKey: 'ck_5513505e706a5a850c71b6ab47d72b097bbb7db4',
  consumerSecret: 'cs_cc89536ca2733670e62a22e1f20e4f4e4daf3461',
  wpAPI: true,
  version: 'wc/v1'
});

var WooCommercelatest = new WooCommerceAPI({
  url: 'https://www.emirateslitfest.com/',
  consumerKey: 'ck_5513505e706a5a850c71b6ab47d72b097bbb7db4',
  consumerSecret: 'cs_cc89536ca2733670e62a22e1f20e4f4e4daf3461',
  wpAPI: true,
  version: 'wc/v3'
});

var downloadreceipt = "https://www.emirateslitfest.com/wp-admin/admin-ajax.php?action=generate_wpo_wcpdf";
var themeurl =  "https://www.emirateslitfest.com/wp-json/eafol/v1/";
export default {
   
  WooCommerce,
  downloadreceipt,
  themeurl,
  WooCommercelatest,
};