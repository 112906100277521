import React from 'react';
import './customers.scss';
import Header from '../Header';
import '../../../node_modules/react-simple-tree-menu/dist/main.css';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import axios from 'axios';
var config = require('../../Api')

class Customers extends React.Component {
    constructor(props) {
    super(props);
   // document.body.classList.add('inner-page');
   this.options = countryList().getData()
    this.state = {
     customers: [],
     fname : "",
     query : "",
     showing: false,
     loading: true,
     buttonname: 'CREATE',
     options: this.options,
      value: {value:'AE',label:'United Arab Emirates' },
      customerserror:"",
     getuser : {
      first_name: "",
      last_name: "",
      email: "",
      billing :  {
      address_1: "",
      address_2: "",
      city: "",
      company: "",
      country: "",
      firstname1: "",
      lastname1: "",
      email1: "",
      postcode: "",
      state: "",
      }
      }
    }

    this.Createuser = this.Createuser.bind(this)
   
}

componentDidMount() {
  this.Getcustomers();
  
      
  }
  
  Getcustomers(){
    var api = config.default.WooCommerce;
    api.getAsync("customers?role=all&per_page=99&orderby=id").then((response) => {
  
     const getcustomers = JSON.parse(response.toJSON().body)
     this.setState({
      customers : getcustomers,
      loading: false,  
    })   
     
      });


  } 
 Createuser(){
  const { getuser }  = this.state 
  //alert(getuser.billing.country);
  if (getuser.email !== "" && getuser.first_name !== "" && getuser.last_name !== "" ) {
    let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if ( re.test(getuser.email) ) {
      console.log("hiiiiii" + getuser.billing.email1)
  const createuser = {
    email: getuser.email,
    first_name: getuser.first_name,
    last_name: getuser.last_name,
    
   

    billing: {
      first_name: getuser.billing.firstname1 === undefined ? getuser.first_name :  getuser.billing.firstname1,
      last_name: getuser.billing.lastname1 === undefined ? getuser.last_name :  getuser.billing.lastname1,
      company:(getuser.billing.company === "" || getuser.billing.company=== undefined)   ? "Unknown" : getuser.billing.company,
      address_1:  (getuser.billing.address_1 === "" || getuser.billing.address_1=== undefined)   ? "Unknown" : getuser.billing.address_1,
      address_2:  (getuser.billing.address_2 === "" || getuser.billing.address_2=== undefined)   ? "Unknown" : getuser.billing.address_2,
      city: (getuser.billing.city === "" || getuser.billing.city=== undefined)   ? "Unknown" : getuser.billing.city,
      state: (getuser.billing.state === "" || getuser.billing.state=== undefined)   ? "Unknown" : getuser.billing.state,
      postcode: (getuser.billing.postcode === "" || getuser.billing.postcode=== undefined)   ? "Unknown" : getuser.billing.postcode,
      country: (getuser.billing.country === "" || getuser.billing.country=== undefined)   ? "Unknown" : getuser.billing.country,
      email:(getuser.billing.email1 === "" || getuser.billing.email1=== undefined)   ? getuser.email :  getuser.billing.email1,
      phone: (getuser.billing.phone === "" || getuser.billing.phone=== undefined)   ? "Unknown" :  getuser.billing.phone 
    },
    shipping: {
      first_name: getuser.billing.first_name,
      last_name: getuser.billing.last_name,
      company: getuser.billing.company,
      address_1: getuser.billing.address_1,
      address_2: getuser.billing.address_2,
      city: getuser.billing.city,
      state: getuser.billing.state,
      postcode: getuser.billing.postcode,
      country: getuser.billing.country
    }
  };
  



  if (this.state.getusersid !== undefined && this.state.getusersid !=="" ){
    var api = config.default.WooCommerce;
    
    api.postAsync("customers/"+ this.state.getusersid, createuser).then((response) => {
      const customers = JSON.parse(response.toJSON().body)
      if( customers.message !== "" &&  customers.message !== undefined){

       // alert("hiiiiiiiiiiiiiiiiii")
        this.setState({
         invaliduserErr:  customers.message,
     });
   
       }else{
         this.setState({
           invaliduserErr:  "Customer Updated Successfully",
       });
   
       }
      })
      .catch((error) => {
       
        this.setState({
          invaliduserErr:error.response.message,
      });
      });


  } else {
   createuser.username= getuser.email
   createuser.password= "123456789!@#$%"
 
  var api = config.default.WooCommerce;
  api.postAsync("customers", createuser).then((response) => {
    const customers = JSON.parse(response.toJSON().body)
    if( customers.message !== "" &&  customers.message !== undefined){

     //alert("hiiiiiiiiiiiiiiiiii")
     this.setState({
      invaliduserErr:  customers.message,
  });

    }else{
      this.setState({
        invaliduserErr:  "Customer Created Successfully",
    });

    }
   
    })
    .catch((error) => {
      this.setState({
        invaliduserErr:error.response.message,
    });
    });

  }

  } else{

    this.setState({
      invaliduserErr:'Please Enter A Valid Email Address',
  });


  }

  } else{

    this.setState({
      invaliduserErr:'Please fill all required fields',
  });


  }

 }
 getsearchvalue = (event) => {
  if(event.key === 'Enter'){
  if (this.state.email !== "" && this.state.email === undefined ){
    var searchcustomers = this.search.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
    if(isNaN(searchcustomers)){
  var api = config.default.WooCommerce;
  api.getAsync('customers?search='+this.search.value+'&role=all&per_page=99').then((response) => {

   const getcustomers = JSON.parse(response.toJSON().body)
   //alert(getcustomers)
   
   this.setState({
    customers : getcustomers   
  }) 
  if(getcustomers.length === 0){

    this.setState({
      customerserror : "There are no Customer"   
    }) 

  }else{
    this.setState({
      customerserror : ""   
    }) 

  }  
   
    });
  } else {

    var apidata = config.default.themeurl;

    axios.get(apidata+"user-contact/"+this.search.value, {})
            .then((response) => {
   
          //  console.log(response.data)
            const getcustomers = response.data
        
           if(getcustomers== false){
         
             this.setState({
               customerserror : "No such customer found" ,
               customers : []
             }) 
         
           }else{
             this.setState({
               customerserror : ""   ,
               customers : getcustomers 
             }) 
            } 
   
            })

          }

  } else {

    this.Getcustomers();  

  }

 }

}

Userupdate(customerid){
  var api = config.default.WooCommerce;
  api.getAsync('customers/'+customerid).then((response) => {

   const getusers = JSON.parse(response.toJSON().body)
  // console.log(getusers)
  
   this.setState({
    getuser : getusers,
    showing: true,
    buttonname: 'UPDATE',  
    getusersid : getusers.id,
    //value : getusers.billing.country
  })   
  
   
    });

}

handleInputFieldChange = (event) => {
  const { target } = event;
  const name = target.name;
  console.log("target",name)
  if(name === "first_name"){
    this.setState(preState => ({
      ...preState,
      getuser: {
          ...preState.getuser,
         [name]: target.value,
          billing :  {
            ...preState.getuser.billing,
            // [name]: target.value,
            
            }
      },
  
 
  }));
  }
  else if(name === "last_name"){
    this.setState(preState => ({
      ...preState,
      getuser: {
          ...preState.getuser,
         [name]: target.value,
          billing :  {
            ...preState.getuser.billing,
            // [name]: target.value,
            
            }
      },
  
 
  }));
  }
  else if(name === "lastname1"){
    this.setState(preState => ({
      ...preState,
      getuser: {
          ...preState.getuser,
        //  [name]: target.value,
          billing :  {
            ...preState.getuser.billing,
            last_name: target.value,
            
            }
      },
  }));
  }
  else if(name === "firstname1"){
    this.setState(preState => ({
      ...preState,
      getuser: {
          ...preState.getuser,
        //  [name]: target.value,
          billing :  {
            ...preState.getuser.billing,
            first_name: target.value,
            
            }
      },
  }));
  }
  else if(name === "email"){
    this.setState(preState => ({
      ...preState,
      getuser: {
          ...preState.getuser,
         [name]: target.value,
          billing :  {
            ...preState.getuser.billing,
            // last_name: target.value,
            
            }
      },
  
 
  }));
  }
  else{
    this.setState(preState => ({
      ...preState,
      getuser: {
          ...preState.getuser,
          [name]: target.value,
          billing :  {
            ...preState.getuser.billing,
            [name]: target.value,
            
            }
      },
  
 
  }));
  }

 


};

changeHandler = value => {
  this.setState({ value })
//alert(JSON.stringify(value))
const values = JSON.stringify(value.value)
  this.setState(preState => ({
    ...preState,
    getuser: {
       // ...preState.getuser,
        //[name]: target.value,
        billing :  {
          ...preState.getuser.billing,
          ['country']: values.replace(/['"]+/g, ''),
          
          }
    },


}));
}

render() {
  const { customers, getuser }  = this.state
  const { showing } = this.state;
 //alert(this.state.value)
    return (

      <div className="el-main">
        {/* Menu */}

        <Header/>
      
        <div className="el-flexrow">
        {/* Left-Section */}
          <div className="el-left el-dsp-block">
            
            {/* Left-Top-Bar */}
            <div className="el-btn-newfol">
              <a href="/"><i className="material-icons">add</i> New Order</a>
            </div>
            {/* Left-Top-Bar */}
            
            {/* Product-List */}
            <div className="el-product-list">
              
              <div className="el-create-cust-btn"  onClick={() => this.setState({ showing: !showing, buttonname: 'CREATE', invaliduserErr:'',   getuser : { 
                first_name: "",
                last_name: "",
                email: "",
       billing :  {
      address_1: "",
      address_2: "",
      city: "",
      company: "",
      country: "",
      first_name: "",
      last_name: "",
      postcode: "",
      state: "",
      }
      } })} >
                <div>Create New Customer</div>
                <i className="material-icons">add</i>
              </div>
              
              {/* Search */}
              <div className="el-search">
                <i className="material-icons">search</i>
                <input type='text' placeholder="Search Customers..."   ref={input => this.search = input}    onKeyPress={this.getsearchvalue} />
              </div>
              {/* Search */}
              
              <div className="el-list-inn">
                {/* Cust-Info-Repeat */}
                {/* <div className="el-no-customertxt">Start typing in the search input to find customers</div> */}
            {this.state.customerserror}
                {this.state.loading == true ?
        <div id="cover-spin" ></div> : null}  
                {customers.map((customer, index) => (
                <div className="el-list-item"  key={index}    onClick={() => this.Userupdate(customer.id)}>
                  <div className="el-listitem-img-container el-id-container">
                    #{customer.id}
                  </div>
                  <div className="el-product-info">
                    <h3>{!!(customer.first_name) ? (customer.first_name +" "+ customer.last_name) : customer.username} </h3>
                    <p className="el-normal">{customer.email}</p>                    
                  </div>
                  <div className="el-add-item">
                    <i className="material-icons">keyboard_arrow_right</i>
                  </div>
                </div>
                ))}


                {/* Cust-Info-Repeat */}                
              </div>
            </div>
            {/* Product-List */}
        </div>
        
        {/* Right-Section */}

        <div className="el-right">
        { showing ?
        <form onSubmit={this.Createuser}>
          {/* Cart */}
          <div className="el-cart">
              {/* <div className="el-empty-cart">
                <div>
                <i class="material-icons">add_shopping_cart</i>
                  <h2>CART EMPTY</h2>
                  <p>Add a product from the list</p>
                </div>
              </div> */}
              {/* OpenOrder */}
              <div className="el-cust-form">
                <h2>Customer Details</h2>
                {/* FirstName */}
                <div className="el-custform-row el-req">
                    <div className="el-custform-label">First Name</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text"    name='first_name'  value={getuser.first_name}     onChange={(event) => this.handleInputFieldChange(event, "first_name")}   required />
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div>
                {/* LastName */}
                <div className="el-custform-row el-req">
                    <div className="el-custform-label">Last Name</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text"  name='last_name'  value={getuser.last_name}  onChange={(event) => this.handleInputFieldChange(event, "last_name")} required />
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div>
                {/* EmailAddress */}
                <div className="el-custform-row el-req">
                    <div className="el-custform-label">Email Address</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input  type="email"   name='email'  value={getuser.email}  onChange={(event) => this.handleInputFieldChange(event, "email")} required/>
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div>
                <h2>Billing Address</h2>
                {/* FirstName */}
                {/* <div className="el-custform-row el-req">
                    <div className="el-custform-label">First Name</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="email"  name='firstname1'  value={getuser.billing.first_name}   onChange={(event) => this.handleInputFieldChange(event, "firstname1")} />
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div> */}
                {/* LastName */}                
                {/* <div className="el-custform-row el-req">
                    <div className="el-custform-label">Last Name</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="email"   name='lastname1'  value={getuser.billing.last_name}   onChange={(event) => this.handleInputFieldChange(event, "lastname1")} />
                      </div>                                                         
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div> */}
                {/* Company */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Company</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='company'  value={getuser.billing.company}   onChange={(event) => this.handleInputFieldChange(event, "company")} />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* Address Line 1 */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Address Line 1</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='address_1'  value={getuser.billing.address_1}   onChange={(event) => this.handleInputFieldChange(event, "address_1")} />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* Address Line 2 */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Address Line 2</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='address_2'  value={getuser.billing.address_2}   onChange={(event) => this.handleInputFieldChange(event, "address_2")}  />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* City */}
                <div className="el-custform-row">
                    <div className="el-custform-label">City</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='city'  value={getuser.billing.city}    onChange={(event) => this.handleInputFieldChange(event, "city")} />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* State */}
                <div className="el-custform-row">
                    <div className="el-custform-label">State</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text"  name='state'  value={getuser.billing.state}   onChange={(event) => this.handleInputFieldChange(event, "state")} />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>
                {/* Postcode */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Postcode</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
           
                        <input type="text" name='postcode'  value={getuser.billing.postcode}   onChange={(event) => this.handleInputFieldChange(event, "postcode")}  />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}  
                </div>
                {/* Country */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Country</div>
            
                    <div className="el-custform-input">
                      <div className="el-forminput el-select">
                       {/*  <input type="text" name='country'  defaultValue={getuser.billing.country}  onChange={(event) => this.handleInputFieldChange(event, "country")} />*/}
                       <Select
                     defaultValue={{value:'AE',label:'United Arab Emirates' }}
                     options={this.state.options}
                     value={this.state.value}
                     onChange={this.changeHandler}
                      />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div>  */}
                </div>
                {/* EmailAddress */}
                {/* <div className="el-custform-row el-req">
                    <div className="el-custform-label">Email Address</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="email" name='email1'   value={getuser.billing.email}   onChange={(event) => this.handleInputFieldChange(event, "email1")}/>
                      </div>
                    </div>
                    <div className="el-custform-reqsign">*</div>
                </div> */}
                {/* Phone */}
                <div className="el-custform-row">
                    <div className="el-custform-label">Phone</div>
                    <div className="el-custform-input">
                      <div className="el-forminput">
                        <input type="text" name='phone'  value={getuser.billing.phone}   onChange={(event) => this.handleInputFieldChange(event, "phone")} />
                      </div>
                    </div>
                    {/* <div className="el-custform-reqsign">*</div> */}
                </div>

              </div>
          
                <p className="text-danger">{this.state.invaliduserErr}</p>
          </div>
          {/* Cart */}
          <div className="el-button-wrap">
            <div className="el-empty"></div>
            {/* <div className="el-btn el-cancel-btn"><i class="material-icons">not_interested</i> Cancel</div> */}
            <div className="el-btn el-checkout-btn"  onClick={this.Createuser}  >{this.state.buttonname} <i className="material-icons">arrow_forward</i></div>            
          </div>  
          </form>  : <div className="el-empty-cart">
                <div>
                <i className="material-icons">person</i>
                  <h2>SELECT CUSTOMER</h2>
                  <p>Select a customer from the list to view their details or create a new customer</p>
                </div>
              </div>
                }
        </div>
        
        {/* Right-Section */}
      </div>
      </div>
    )}
}

export default Customers;