import React from 'react';
import { Route,  BrowserRouter as Router } from 'react-router-dom'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Saleshistory from './components/Saleshistory';
import Customers from './components/Customers';


function App() {
  return (
    <MuiThemeProvider>
    <div className="App">
 <Router>
   
      <Route exact path="/" component={Dashboard} />
     
      <Route path="/sales-history" component={Saleshistory}/>
      <Route path="/customers" component={Customers}/>
      <Route path="/login" component={Login}/>
  </Router>    
    </div></MuiThemeProvider>
  );
}

export default App;
