import React, { useState, useEffect } from 'react';
import {Link, Redirect} from 'react-router-dom';
import './header.scss';
//import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HistoryIcon from '@material-ui/icons/History';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import '../../../node_modules/react-simple-tree-menu/dist/main.css';
import {reactLocalStorage} from 'reactjs-localstorage';
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function Header(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

 
  useEffect(() =>  {

  let user = null;
  let loginuser = reactLocalStorage.get('login', true);

  try {
      user = reactLocalStorage.getObject('user');
  } catch (error) {
      reactLocalStorage.remove('user');
      loginuser = "false";
  }

  //this.setState({
   //  loginusers: user,
  //});

  if (loginuser !== "true") {
    window.location = '/login' 
  }
} )

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };




  const logoutuser = () => {
        reactLocalStorage.clear();
        window.location = '/login' 
   }
        return (
            <div className="el-btn-menu">
   
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                //onClick={handleDrawerOpen}
                onClick={toggleDrawer('left', true)}
                edge="start"
               // className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
                <span className="el-menu-label">Menu</span>
              </IconButton>
          
            </Toolbar>
         
            <SwipeableDrawer
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
      <div className={classes.drawerHeader}>
              <IconButton onClick={toggleDrawer('left', false)}>
                 <CloseIcon/> 
                 <span className="el-menu-label">Menu</span>
              </IconButton>
            </div>
            
            <Divider />
            <List>
           
                <ListItem button  component={Link} to='/'>
                  <ListItemIcon> <ShoppingCartIcon/></ListItemIcon>
                  <ListItemText primary="Sale" />
                </ListItem> <Divider />
                <ListItem button component={Link} to='/sales-history'>
                <ListItemIcon> <HistoryIcon/></ListItemIcon>
                <ListItemText primary="Sales History" />
              </ListItem> <Divider />
              <ListItem button component={Link} to='/customers'>
             <ListItemIcon> <PersonIcon/></ListItemIcon>
              <ListItemText primary="Customers" />
            </ListItem> <Divider />
            <ListItem button  onClick={logoutuser} >
            <ListItemIcon> <SettingsIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
            
            </List>
            <Divider />
           
          </SwipeableDrawer>
            </div>
        )



}