import React from 'react';
import $ from "jquery";
import {reactLocalStorage} from 'reactjs-localstorage';
$(document).ready(function(){
  $(document).on("click",".el-list-inn .el-list-item",function(){
      var id = $(this).attr("data-id");
      //alert(1);
      if($(".el-prdlist-item[data-id='"+id+"']").length > 0){
        //alert(2); 
        $(".el-prdlist-item[data-id='"+id+"']").find(".material-icons.el-green").trigger("click");
      }
  });


 });
    var toggle = false
class Product extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        qty: 0
      };
      this.add = this.add.bind(this);
      this.subtract = this.subtract.bind(this);
     
    }
    componentDidMount() {
   
        this.add()
      
    }

    add() {
        this.setState({
          qty: this.state.qty + 1
        });
        this.props.handleTotal(parseFloat(this.props.price), this.state.qty);

          var obj={
          id : this.props.id,
          qty:this.state.qty + 1
          }
        var key = "product"+this.props.id
        reactLocalStorage.setObject(key, obj);
        //reactLocalStorage.setObject('quantity', this.state.qty);
      
    }
  
    subtract() {
      this.setState({
        qty: this.state.qty - 1
      });
      this.props.handleTotal(parseFloat(-this.props.price), -this.state.qty);
      var obj={
        id : this.props.id,
        qty: this.state.qty - 1
        }
      var key = "product"+this.props.id
      reactLocalStorage.setObject(key, obj);
      //reactLocalStorage.setObject('addtocartproduct', [this.props.id, this.state.qty]);
      //reactLocalStorage.setObject('quantity', this.state.qty);
    }
  
    render() {
      //alert(this.props.outstockv) 
     
      return (
        <div style={{display : this.state.qty !== 0 ? 'block': 'none'}}> 
        {/* {this.state.qty !== 0 ?    */}
<div className="el-prdlist-item" key={this.props.id}  id={'data'+this.props.id}  data-id={this.props.id}>
  
{/* <div className="el-listitem-img-container">
  <img src={this.props.images} alt="img1" />  
  </div> */}
<div className="el-product-info test"><h3>{this.props.name}</h3><span> Event No: {this.props.sku} | STOCK: {this.props.stcockquantity} </span><p>AED {this.props.price} </p></div>
<div className="el-cartline-total"><span>AED {this.props.price* this.state.qty}</span></div>
<div className="el-cartline-qty">{this.state.qty}</div>
<div className="el-cartline-btns">
  { (this.props.stcockquantity > this.state.qty || this.props.managestock == false || this.props.outstockv == true) ?
    <i className="material-icons el-green" onClick={this.add} >add_circle_outline</i>:
    <i className="material-icons el-green" >not_interested</i>
  }
  
  <i className="material-icons el-red"  onClick={this.state.qty < 1 ? null : this.subtract} >remove_circle_outline</i>
</div> 
</div> 
 {/* :null   */}
</div>
      );
    }
  }
  export default Product