import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import axios from 'axios';
import {reactLocalStorage} from 'reactjs-localstorage';
import './Login.scss';
import LoadingScreen from 'react-loading-screen';

const logo = require('./logo.png');
//const cors = require('cors');
var querystring = require('querystring');
var config = require('../../Api')

class Login extends React.Component {
    constructor() {
        super();
        //document.body.classList.remove('home-page');
        this.state = {
            user: {
                email: '',
                password: '',
            },
            open: false,
            showPassword: false,
            loading: true,

        };
    }

    handleInputFieldChange = (event, name) => {
        const {target} = event;
        this.setState(preState => ({
            ...preState,
            user: {
                ...preState.user,
                [name]: target.value
            }
        }));


    };

    componentDidMount() {
        this.userlogincheck();
        setTimeout(() =>
                this.setState({loading: false})
            , 1500)
    }

    userlogincheck = () => {

        const loginuser = reactLocalStorage.get('login', true);
        const loginusers = reactLocalStorage.getObject('user');
        this.setState({loginusers});
        //console.log(loginusers);
        if (loginuser === "true") {

            this.props.history.push("/");
        }


    }


    loginusersubmit = () => {
        const {user} = this.state;
        var api = config.default.themeurl;
        //alert(JSON.stringify(api))
        if (user.email !== "" && user.password !== "") {
      
let axiosConfig = querystring.stringify({
  headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
	  'X-Requested-With': 'XMLHttpRequest' 
  }
});
            let data = querystring.stringify({
                username : user.email,
                password: user.password
            });
            axios.post(api + 'login', data).then(res => {
                const persons = res.data;
//console.log(persons.response)
                if (persons.response === true) {

                    this.setState({persons});
                    reactLocalStorage.set('login', true);
                    reactLocalStorage.setObject('user', persons.user_id);
                    const loginuser = reactLocalStorage.get('login', true);
					//reactLocalStorage.set('token', persons.success);
                    if (loginuser === "true") {
	reactLocalStorage.setObject('permissions', persons.permissions);
//const token = reactLocalStorage.get('token', true);
	const loginuser = reactLocalStorage.getObject('user');
		
                        this.props.history.push("/");
                    }
                } else {
                    const persons = res.data;
                    this.setState({
                        invaliduserErr: persons.error_msg,
                    });
                }

            })
        } else {
                   
                    this.setState({
                        invaliduserErr:'Please fill all required fields',
                    });
                }

    }

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    render() {
        const {user} = this.state;
        const {classes} = this.props;
        const {loading} = this.state
        return (
            <LoadingScreen
                loading={loading}
                bgColor='#f1f1f1'
                spinnerColor='#9ee5f8'
                textColor='#676767'

            >
                <div className={classes.root}>
                    <div className="main-logo"><img src={logo} alt="Emirates Logo"/></div>

                    <div className="flex-container">
                        <div className="login-form">
                            <header className="login-head">
                                <h1>Sign In</h1>
                                <p>Please enter your email address and password to access Emirates
                                    Portal.</p>
                            </header>
                            <form className={classes.container} noValidate autoComplete="off">
                                <ValidatorForm
                                    className=""
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div className="form-group">
                                        <label>Email</label>
                                        <TextValidator
                                            required
                                            InputLabelProps={{
                                                shrink: true
                                            }}

                                            id="email"
                                            name="email"
                                            className={classes.textField}
                                            value={user.email}
                                            placeholder="example@gmail.com"
                                            onChange={(event) => this.handleInputFieldChange(event, "email")}
                                            validators={["required", 'isEmail']}
                                            errorMessages={["this field is required", 'invalid email']}
                                            margin="normal"
                                            InputProps={{
                                                style: {
                                                    color: "black"
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="form-group pass-block">
                                        <label>Password</label>
                                        <TextValidator
                                            id="outlined-password-input"
                                            margin="normal"
                                            placeholder="••••••••"
                                            name="password"

                                            className={classes.textField}
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            value={user.password}
                                            onChange={(event) => this.handleInputFieldChange(event, "password")}
                                            validators={["required"]}
                                            errorMessages={["this field is required"]}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                        >
                                                            {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    color: "black"
                                                }
                                            }}
                                        />
                                        {/* <Button href="/forgotpassword" className="forgot-pass">Forgotten 
                                            Password?</Button> */}
                                    </div>

                                    <p className="text-danger">{this.state.invaliduserErr}</p>

                                    <div className="primary-btn">
                                        <Button variant="contained"
                                                type="submit" className={classes.button} onClick={this.loginusersubmit}>
                                            Sign In
                                        </Button>
                                    </div>

                                </ValidatorForm>
                            </form>
                {/* <Button href="/signup" className="loginbutton"> Don't have an account? <strong>Sign 
                                up </strong></Button>*/}
                        </div>

                    </div>
                </div>
            </LoadingScreen>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = {

    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    errorStyle: {
        position: 'absolute',
        marginBottom: '-22px',
        color: 'black',
    },
    button: {
        height: 50,
        backgroundColor: '#fff'

    },

};

export default withStyles(styles)(Login);
