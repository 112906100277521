import React from 'react';
class Total extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        
      let total = parseFloat(this.props.total).toFixed(2);
      let subtotal = parseFloat(this.props.total / 1.05).toFixed(2);
      let  tax = parseFloat(+total - +subtotal).toFixed(2);
      let quty = this.props.quty
      return (
<div className="el-totalof-cart">
                <div className="el-sub-total">
                  <div className="el-st-row">
                    <div className="el-st-firstcol">Subtotal</div>
                    <span>AED {subtotal}</span>
                  </div>
                  <div className="el-st-row">
                    <div className="el-st-tax">VAT (5%)</div>
                    <span>AED {tax}</span>
                  </div>
                  <div className="el-st-row">
                    <div className="el-st-firstcol"><strong>Total</strong></div>
                    <span><strong>AED {total}</strong></span>
                  </div>
                </div>
                <div className="el-total-item">
                  <strong>{quty} items</strong>
                </div>
              </div>
      );
    }
  }
  export default Total