import React from 'react';
import './saleshistory.scss';
import Header from '../Header';
import '../../../node_modules/react-simple-tree-menu/dist/main.css';
import Moment from 'react-moment';
//import productimage from './724.jpg';
import axios from 'axios';
import {reactLocalStorage} from 'reactjs-localstorage';
import { Modal } from 'react-bootstrap';
var config = require('../../Api')


var exist = false
var number = 0
class Saleshistory extends React.Component {
    constructor(props) {
    super(props);
   // document.body.classList.add('inner-page');
    this.state = {
      saleshistory : [],
      orderdetail: {
        line_items: [],

      },
      showing: false,
      selectValue:"1", 
      loading: true,
      ordersuccessed: false,
      ticketpdf:[]
    
    }
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
}

componentDidMount() {
  this.Getsaleshistory();
  
      
  } 
  Getsaleshistory(){
    exist = false
   
      var api = config.default.WooCommerce;
      var getuserid = reactLocalStorage.getObject('user');
      api.getAsync('orders?filter[meta_key]=orderuserid&filter[meta_value]='+getuserid+'&status=completed&per_page=99').then((result) => {  
    
       const getsaleshistory =  JSON.parse(result.toJSON().body)
       this.setState({
        saleshistory : getsaleshistory,
        loading: false,   
      })   
       
        });
  
  }

  Getorderdetail= (orderid) =>{
    exist = false
    this.setState({ 
      loading: true, 
    }) 
    var api = config.default.WooCommerce;
    api.getAsync("orders/"+orderid).then((result) => {
  
     const orderdetails =  JSON.parse(result.toJSON().body)
     this.setState({
      orderdetail : orderdetails,
      showing: !this.state.showing,
      showing: true,  
      loading: false, 
    })   
     
      });

  }
  handleDropdownChange(e) {
    this.setState({ selectValue: e.target.value ,
      loading: true,    });
    exist = false
  if(e.target.value==1){
   // alert(e.target.value)
    exist = false
    // var api = config.default.WooCommerce;
    // var getuserid = reactLocalStorage.getObject('user');
    // api.getAsync('orders?filter[meta_key]=orderuserid&filter[meta_value]='+getuserid+'&status=completed&per_page=99').then((result) => {  
  
    //  const getsaleshistory =  JSON.parse(result.toJSON().body)
    //  this.setState({
    //   saleshistory : getsaleshistory,
    //   loading: false,   
    // })   
     
    //   });
    this.Getsaleshistory();


  }else{

    
    var api = config.default.WooCommerce;
    api.getAsync('orders?status=completed&per_page=99').then((result) => {
  
     const getsaleshistory =  JSON.parse(result.toJSON().body)
     this.setState({
      saleshistory : getsaleshistory,
      loading: false,   
    })   
     
      });

  }
  }


  ordersuccessegetticket = () => {
    var apidata = config.default.themeurl;
    this.setState({
     
     loading: true,
    }) 
      axios.get(apidata+"pdf-url/"+this.state.orderdetail.id, {})
      .then((response) => {
       
        this.setState({
         ticketpdf : response.data,
         
         ordersuccessed: true,
        
         loading: false,
       })   
      
      })
    }
    ordersuccessedhideModal = () => {
      this.setState({ ordersuccessed: false });
    }
    ordersuccessedshowModal = () => {
      this.setState({ ordersuccessed: true });
    }
render() {
  const { saleshistory, orderdetail }  = this.state
  const { showing } = this.state;
  var downloadreceipt = config.default.downloadreceipt; 
//console.log(this.state.saleshistory)
exist = false
    return (

      <div className="el-main">
        {/* Menu */}
        <Header/>


        <Modal className="el-ordersuccessed-wrap" show={this.state.ordersuccessed} handleClose={this.ordersuccessedhideModal}>
                          <div className="el-cancelorder el-downloadpopup">
                              <div className="el-model-title">Download Ticket Here</div>
                          <p>Click on below links to download Ticket PDF  ORDER ID : {orderdetail.id}</p>
                             <div className="el-left el-downloadtick-wrap">
                                <div className="el-button-wrap el-flxdr">
                  
                                {this.state.ticketpdf.map((ticketpdfs, index) => (
                                <div className="el-btn el-checkout-btn" key={index}>
                                  <a href={ticketpdfs.url}    target="_blank">
                                    <i className="material-icons">local_printshop</i> Download Ticket PDF   -- {ticketpdfs.title}
                                  </a>
                                </div>     ))}       
                                 
                  
                                </div>
                              </div>
                              <div className="el-right el-download-closebtn">
                                <div className="el-btn el-checkout-btn" onClick={this.ordersuccessedhideModal} > Close</div>   
                              </div>
                             {/* Checkout Container END */}                              
                              
                          </div>
                          </Modal>   
        <div className="el-flexrow">
        {/* Left-Section */}
          <div className="el-left">
            
            {/* Left-Top-Bar */}
            <div className="el-btn-newfol">
              <a href="/"><i className="material-icons">add</i> New Order</a>
            </div>

            <div className="el-product-list">
      
              <div className="el-search">
                <i className="material-icons">filter_list</i>
                {/* <input type='text' placeholder="Search products..." /> */}
                <select onChange={this.handleDropdownChange} >
                <option value="1">My Orders</option> 
                  <option value="2">All Orders</option>
                  
                </select>
              </div>
              {/* Search */}
              
              <div className="el-list-inn el-salesh-wrap">
                {/* Product-Item-Repeat */}
                {this.state.loading == true ?
        <div id="cover-spin" ></div> : null}  
                {saleshistory.map((sales, i, arr) => {
  //if(sales.created_via == 'rest-api' && this.state.selectValue ==="2"){ 
                 
//count
if( this.state.selectValue ==="2"){ 
var numBoys = arr.reduce(function (n, person) {
  return n + (person.date_created.split('T')[0] == arr[i]['date_created'].split('T')[0]);
}, 0);



  const previousItem = i== 0 ? arr[0] : arr[i-1];
  const item = i== 0 ? arr[0] : arr[i];
  const items =  item.date_created.split('T')
  const previousItems =  previousItem['date_created'].split('T')
  const previousItemes = previousItems[i-1]

  if(previousItems[0]!= items[0]){

  return(
   
    <div key={i}>
    {(  previousItems[0]!= items[0]) ?                     
    <div className="el-day-header">
    
        <div className="el-dh-date"> <Moment format="D MMM YYYY" >
      {items[0] }
         </Moment></div> 
        <div className="el-dh-roder">{numBoys} Order</div>
        <i className="material-icons">insert_chart_outlined</i>
    </div> : null }
    <div className="el-list-item"  onClick={() => this.Getorderdetail(sales.id)}>
    <div className="el-product-info">
      <h3>Order# {sales.id} </h3>
      <span>
      <Moment format="hh:mm a" withTitle>
      {sales.date_completed}
         </Moment></span>  
      <p className="el-normal">{sales.status}</p>
    </div>
    <div className="el-prd-item">{sales.line_items.length} items</div>
    <div className="el-prdorder-total">
      <span>{sales.currency} {sales.total}</span></div>  
    <div className="el-add-item">
      <i className="material-icons">keyboard_arrow_right</i>
    </div>
    </div>
    </div>
      )



  }else if(previousItems[0]=== items[0]&&  exist != true){
    // number=1
    exist = true
    return(
   
      <div key={i}>
                         
      <div className="el-day-header">
      
          <div className="el-dh-date"> <Moment format="D MMM YYYY" >
        {previousItems[0]}
           </Moment></div> 
          <div className="el-dh-roder">{numBoys} Order</div>
          <i className="material-icons">insert_chart_outlined</i>
      </div>   
      <div className="el-list-item"  onClick={() => this.Getorderdetail(sales.id)}>
      <div className="el-product-info">
        <h3>Order# {sales.id}</h3>
        <span>
        <Moment format="hh:mm a" withTitle>
        {sales.date_completed}
           </Moment></span>  
        <p className="el-normal">{sales.status}</p>
      </div>
      <div className="el-prd-item">{sales.line_items.length} items</div>
      <div className="el-prdorder-total">
        <span>{sales.currency} {sales.total}</span></div>  
      <div className="el-add-item">
        <i className="material-icons">keyboard_arrow_right</i>
      </div>
      </div>
      </div>
        )

  }else{
    
    return(
   
      <div key={i}>
      <div className="el-list-item" onClick={() => this.Getorderdetail(sales.id)} > 
      <div className="el-product-info">
        <h3>Order# {sales.id}</h3>
        <span>
        <Moment format="hh:mm a" withTitle>
        {sales.date_completed}
           </Moment></span>  
        <p className="el-normal">{sales.status}</p>
      </div>
      <div className="el-prd-item">{sales.line_items.length} items</div>
      <div className="el-prdorder-total">
        <span>{sales.currency} {sales.total}</span></div>  
      <div className="el-add-item">
        <i className="material-icons">keyboard_arrow_right</i>
      </div>
      </div>
      </div>
        )
  }

  } else if(this.state.selectValue ==="1") {

var numBoys = arr.reduce(function (n, person) {
  return n + (person.date_created.split('T')[0] == arr[i]['date_created'].split('T')[0]);
}, 0);


  const previousItem = i== 0 ? arr[0] : arr[i-1];
  const item = i== 0 ? arr[0] : arr[i];
  const items =  item.date_created.split('T')
  const previousItems =  previousItem['date_created'].split('T')
  const previousItemes = previousItems[i-1]

  if(previousItems[0]!= items[0]){
 
  return(
   
    <div key={i}>
    {(  previousItems[0]!= items[0]) ?                     
    <div className="el-day-header">
    
        <div className="el-dh-date"> <Moment format="D MMM YYYY" >
      {items[0] }
         </Moment></div> 
        <div className="el-dh-roder">{numBoys} Order</div>
        <i className="material-icons">insert_chart_outlined</i>
    </div> : null }
    <div className="el-list-item"  onClick={() => this.Getorderdetail(sales.id)}>
    <div className="el-product-info">
      <h3>Order# {sales.id} </h3>
      <span>
      <Moment format="hh:mm a" withTitle>
      {sales.date_completed}
         </Moment></span>  
      <p className="el-normal">{sales.status}</p>
    </div>
    <div className="el-prd-item">{sales.line_items.length} items</div>
    <div className="el-prdorder-total">
      <span>{sales.currency} {sales.total}</span></div>  
    <div className="el-add-item">
      <i className="material-icons">keyboard_arrow_right</i>
    </div>
    </div>
    </div>
      )



  }else if(previousItems[0]=== items[0]&&  exist != true){
    // number=1
    exist = true
    return(
   
      <div key={i}>
                         
      <div className="el-day-header">
      
          <div className="el-dh-date"> <Moment format="D MMM YYYY" >
        {previousItems[0]}
           </Moment></div> 
          <div className="el-dh-roder">{numBoys} Order</div>
          <i className="material-icons">insert_chart_outlined</i>
      </div>   
      <div className="el-list-item"  onClick={() => this.Getorderdetail(sales.id)}>
      <div className="el-product-info">
        <h3>Order# {sales.id}</h3>
        <span>
        <Moment format="hh:mm a" withTitle>
        {sales.date_completed}
           </Moment></span>  
        <p className="el-normal">{sales.status}</p>
      </div>
      <div className="el-prd-item">{sales.line_items.length} items</div>
      <div className="el-prdorder-total">
        <span>{sales.currency} {sales.total}</span></div>  
      <div className="el-add-item">
        <i className="material-icons">keyboard_arrow_right</i>
      </div>
      </div>
      </div>
        )

  }else{
 
    return(
   
      <div key={i}>
    
      <div className="el-list-item" onClick={() => this.Getorderdetail(sales.id)} > 
      <div className="el-product-info">
        <h3>Order# {sales.id}</h3>
        <span>
        <Moment format="hh:mm a" withTitle>
        {sales.date_completed}
           </Moment></span>  
        <p className="el-normal">{sales.status}</p>
      </div>
      <div className="el-prd-item">{sales.line_items.length} items</div>
      <div className="el-prdorder-total">
        <span>{sales.currency} {sales.total}</span></div>  
      <div className="el-add-item">
        <i className="material-icons">keyboard_arrow_right</i>
      </div>
      </div>
      </div>
        )
  }






  }
  
            })}


                
                {/* Product-Item-Repeat */}                
              </div>
            </div>
            {/* Product-List */}
        </div>
        
        {/* Right-Section */}
        <div className="el-right">
        { showing ?
        <div>
          <div className="el-cart">
           
              <div className="el-cart-content">
                <div className="el-open-order">
                  <div className="el-or-left">
                      <div className="el-or-title">Order# {orderdetail.id}</div>
                      <div className="el-customer-btn">
                        <i className="material-icons">person</i>
                        <div className="el-customerbtn-info">
                          <div className="el-customer-name">{orderdetail.billing.first_name} {orderdetail.billing.last_name}  </div>
                        </div>
                      </div>
                  </div>
                  <div className="el-or-right">
                    <div className="el-order-status">{orderdetail.status}</div>
                    <div className="el-order-date">  <Moment format="D MMM YYYY hh:mm a" >{orderdetail.date_completed}</Moment> </div>
                    <div className="el-order-pmethod">
                      <i className="material-icons">payment</i> {orderdetail.payment_method_title}
                    </div>
                  </div>
                </div>
                <div className="el-order-contain">
                  { (orderdetail.line_items).map((itemdetail,index) => (
                     <div className="el-prdlist-item" key={index}>
                     {/* <div className="el-listitem-img-container"><img src={productimage} alt="img1" /></div> */}
                     <div className="el-product-info"><h3> {itemdetail.name}</h3><span>ID:  {itemdetail.id} | SKU: {itemdetail.sku}</span><p>AED {itemdetail.price}</p></div>
                     <div className="el-cartline-total"><span>AED {itemdetail.total}</span></div>
                     <div className="el-cartline-qty">{itemdetail.quantity}</div>                      
                   </div>


                  ))}
                  

                </div>
              </div>
            
              <div className="el-totalof-cart">
                <div className="el-sub-total">
                  <div className="el-st-row">
                    <div className="el-st-firstcol">Subtotal(excl)</div>
                    <span>{orderdetail.currency}  {orderdetail.total - orderdetail.total_tax}</span>
                  </div>
                  <div className="el-st-row">
                    <div className="el-st-tax">TAX</div>
                    <span>{orderdetail.currency} {orderdetail.total_tax}</span>
                  </div>
                  <div className="el-st-row">
                    <div className="el-st-firstcol"><strong>Total</strong></div>
                    <span><strong>{orderdetail.currency} {orderdetail.total}</strong></span>
                  </div>
                </div>
                <div className="el-total-item">
                <strong>{orderdetail.line_items.length} items</strong>
                </div>
              </div>
          </div>
         
          <div className="el-button-wrap">
            <div className="el-empty ds-downloadpdf" onClick={this.ordersuccessegetticket}> <i className="material-icons">local_printshop</i> Download Ticket PDF </div>
         
            <div className="el-btn el-checkout-btn" >
            <a href={downloadreceipt+ '&document_type=invoice&order_ids='+orderdetail.id+'&order_key='+orderdetail.order_key}    target="_blank">
              <i className="material-icons">local_printshop</i> Receipt
              </a>
              </div>            
          </div>
          </div>  : 
         <div className="el-empty-cart">
         <div>
         <i className="material-icons">history</i>
           <h2>SELECT ORDER</h2>
           <p>Select an order from the list to view its summary</p>
         </div>
       </div>
         
         }
        </div>
        
      </div>
      </div>
    )}
}


export default Saleshistory;